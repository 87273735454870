.blogpost {
	font-family: "Montserrat", sans-serif;
	color: #000000;
}

.blogpost h1 {
	font-style: normal;
	font-weight: 800;
	font-size: 36px;
	line-height: 48px;
}

.blogpost p {
	font-style: italic;
	font-weight: 500;
	font-size: 18px;
	line-height: 28px;
}

.cover-img {
	width: 100%;
}
.cover-img img{
	width: 100%;
}
.px-magic {
	padding: 0 10vw;
}

@media (max-width: 500px) {
	.px-magic {
		padding: 0 3vw;
	}
}
