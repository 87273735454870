.age-challenge{
    background-color: black;
    padding-bottom: 15px;
}
.age-challenge h2{
    font-weight: 900;
    font-size: 30px;
	font-family: 'Poppins';
	font-style: normal;
	color: #fff;
    line-height: 50px;
    margin-bottom: 40px;
    padding-top: 40px;
}
.age-challenge span{
    color: blue;
}
.marquee-slider1{
    margin-bottom: 40px;
}
.marquee-slider1 p{
    font-size: 14px;
}
.full-line-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.marquee-separator {
    width: 100%;
    height: 2px;
    background-color: #fff; /* Change to match your design */
    
    margin: 0 ;
    padding: 0;
}
#marquee-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    margin: 20px 60px; 
}