/* Popup style */
.popup-box {
    position: fixed;
    background: #08080880;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
  }
   
  .box {
    position: relative;
    width: 30%;
    margin: 0 auto;
    height: auto;
    max-height: 100vh;
    margin-top: calc(100vh - 80vh - 20px);
    background: #fff;
    border-radius: 12px;
    padding: 20px;
    overflow: auto;
    z-index: 10;
  }
   
  .close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(30% - 20px);
    top: calc(100vh - 82vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
  }
  @media(max-width:800px){
    .box {
      position: relative;
      width: 75%;
      margin: 0 auto;
      height: auto;
      max-height: 80vh;
      margin-top: calc(100vh - 85vh - 20px);
      background: #fff;
      border-radius: 12px;
      padding: 20px;
      overflow: auto;
      z-index: 10;
    }
    .close-icon {
      content: 'x';
      cursor: pointer;
      position: fixed;
      right: calc(8% - 20px);
      top: calc(100vh - 94vh - 33px);
      background: #ededed;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      line-height: 20px;
      text-align: center;
      border: 1px solid #999;
      font-size: 20px;
    }
  }