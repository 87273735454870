.articlepost-tophead {
	position: relative;
}

.articlepost-tophead h1 {
	font-family: "Montserrat", sans-serif;
	color: white;
	font-weight: 700;
	font-size: 36px;
	line-height: 48px;
	position: absolute;
	top: calc(40px + 50%);
	left: 50%;
	text-transform: capitalize;
	transform: translate(-50%, -50%);
}

.articlepost-tophead .cover-img {
	position: relative;
	z-index: -1;
	width: 100%;
	top: 0;
	left: 0;
}

/* .navbar-light .navbar-nav .nav-link {
	color: white !important;
} */

@media (max-width: 800px) {
	.articlepost-tophead {
		margin-top: -25px;
		text-align: center;
	}
	.articlepost-tophead h1 {
	font-family: "Montserrat", sans-serif;
	color: white;
	font-weight: 500;
	font-size: 28px;
	line-height: 35px;
	position: absolute;
	top: calc(60px + 50%);
	left: 50%;
	text-transform: capitalize;
	transform: translate(-50%, -50%);
	}
	.articlepost-tophead .cover-img {
		position: relative;
		z-index: -1;
		width: 100%;
	    height: 200px;

	}
}