.personalcare-tophead {
	position: relative;
}
.personalcare-tophead .image-view .para-content{
	border: 2px solid #3157ef;
	border-radius: 45px;
	padding: 2px;
	text-align: center;
	margin-left: 10px;
	height: 34px;
	margin-top: 14px;
	font-size: 11px;
	font-weight: 500;
	color: #3157ef;
	background-color: white;
	
}
.personalcare-tophead .personal-content h1{
    margin-top: 25px;
    font-weight: 1000;
    font-size: 30px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-style: normal;
	margin-bottom: 20px;
	color: #060918;
}
.personalcare-tophead .personal-content h2{
    margin-top: 25px;
    font-weight: 1000;
    font-size: 30px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-style: normal;
	margin-bottom: 20px;
	color: #060918;
}
.personalcare-tophead .personal-content b{
    color: #060918;
	
}

.personalcare-tophead .personal-content ul {
	margin: 0;
	padding: 0;
	list-style: none;
	margin-top: 12px;
  }
.personalcare-tophead .personal-content ul li{
    font-family: 'Manrope';
    font-style: normal;
    /*text-align: justify;*/
    font-weight: 530;
    font-size: 16px;
    line-height: 28px;
    padding-bottom: 10px;
    margin-top: 0px;
	color: #3F3F3F;
}
.element {
	hyphens: none ;
}
.personalcare-tophead .personal-content p{
    font-family: 'Manrope';
    font-style: normal;
    /*text-align: justify;*/
    font-weight: 500;
    font-size: 17px;
    line-height: 28px;
    padding-bottom: 15px;
    color: #3F3F3F;
	margin-top: 20px;
}
.personalcare-tophead .position-imagefix{
	position: absolute;
    width: 53vw;
	height: 540px;
	padding-top: 10px;
	z-index: -1;
}
.personal-contentfull{
	font-size: 16px;
	color: white;
	text-align: center;
	padding-top: 50px;
	padding-bottom: 50px;
	font-weight: 550;
}
.personal-contentfull h2{
	font-weight: 1000;
	color: white;
}
.image-view {
	display: flex;
	
}
@media(min-width: 1600px) and (max-width: 2500px) {
    .personal-data{
		margin-top: 140px;
	}
}
@media(min-width: 450px) and (max-width: 768px) {
    .personal-data{
		margin-top: 100px;
	}
}

@media (max-width: 800px) {
	.personalcare-tophead{
		margin-top: 80px;
		font-size: 14px;
	}
	.personalcare-tophead .position-imagefix{
		position: relative;
		margin-left: 0px;
		width: 115%;
		height:260px;
	}
	.personalcare-tophead .row>* {
		padding-left: 0;
	}
	.image-view {
        flex-direction: column;
        align-items: flex-start;
    }
    .image-view img {
        margin-bottom: 10px;
    }

    .personal-contentfull{
		padding-left: 0px;
		padding-right: 0px;
	}
	.personal-contentfull h2{
		margin-top: 20px;
		font-size: 18px;
	}
	.image-view {
		flex-direction: row; 
	}
	.personal-contentfull .quard {
        width: 50%; 
    }
}