.terms-content h1{
    color: #3F3F3F;
    text-align: center;
    font-weight: 600;
    font-style: normal;
    margin-bottom: 20px;
    font-family: Manrope, sans-serif;
} 
.terms-content{
    margin: 20px;
}
.terms-content p{
  font-size: 16px;
  font-weight: 500;
  /*text-align: justify;*/
  color: #3F3F3F;
  line-height: 28px;
  font-family: 'Manrope, sans-serif';
}
.terms-content h3{
    color: #3F3F3F;
    font-weight: 550;
    font-size: 18px;
    margin-top: 8px;
    font-family: Manrope, sans-serif;
}
.terms-content ol.d {
    list-style-type: lower-alpha;
}
.terms-content ol li{
  font-size: 16px;
  font-weight: 500;
  /*text-align: justify;*/
  color: #3F3F3F;
  line-height: 28px;
  font-family: 'Manrope, sans-serif';
  margin-left: -15px;
}
.terms-content ul li{
  font-size: 16px;
  font-weight: 500;
  /*text-align: justify;*/
  color: #3F3F3F;
  line-height: 28px;
  font-family: 'Manrope, sans-serif';
  margin-left: -15px;
}
.terms-content h6{
    font-size: 15px;
    color: #3F3F3F;
    font-weight: 500;
    font-family: Manrope, sans-serif;
    margin-top: 0px;
    margin-left: -3px;
}
@media(max-width:800px){
    .terms-content{
        margin: 2px;
    }
    .terms-content p{
        margin-left: 0px;
    }
    .terms-content ol.d {
        list-style-type: lower-alpha;
    }
    .terms-content ol li{
        color: black;
        font-size: 17px;
        margin-left: 0px;
        line-height: 25px;
        margin-top: 5px;
    }
    .terms-content ul li{
        color: black;
        font-size: 17px;
        margin-left: 0px;
        line-height: 25px;
        margin-top: 5px;
    }
}