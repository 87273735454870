.partnership-tophead{
	position: relative;
}
.partnership-tophead .partnership-content h1{
    margin-top: 25px;
    font-weight: 900;
    font-size: 38px;
	font-family: 'Poppins';
	font-style: normal;
	color: #060918;
    line-height: 55px;
    text-align: center;
}
.partnership-tophead .partnership-content span{
    color: blue;
}
.partnership-tophead .partnership-content .partner-para{
    text-align: center;
    font-size: 20px;
    font-family: 'Poppins';
}
.partnership-tophead .partnership-content  p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: black;
	margin-top: 20px;
}
.partnership-tophead .partnership-content .box-highlight{
    border-radius: 8px;
    background-color: rgb(231, 231, 235);
    padding: 25px;
    height:100%;
}
.partnership-tophead .partnership-content h2{
    margin-top: 25px;
    font-weight: 900;
    font-size: 38px;
	font-family: 'Poppins';
	font-style: normal;
	color: #060918;
    line-height: 55px;
    text-align: center;
}
.partnership-tophead .partnership-content  ul{
    list-style-type: square;
    margin-left: 20px;
}
.partnership-tophead .partnership-content  ul li{
   
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: black;
	margin-top: 5px;
    
}
@media (max-width: 800px) {
  
    .partnership-tophead .partnership-content .box-highlight{
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .partnership-tophead .partnership-content img{
        margin-bottom: 30px;  
    }
    .response{
        margin-top: 40px;
    }
    .partnership-tophead .partnership-content  p{
        font-size: 18px; 
    }
    .partnership-tophead .partnership-content h2{ 
        font-size: 30px; 
    }
    .partnership-tophead .partnership-content  ul li{
       margin-left: -20px;
    }  
}