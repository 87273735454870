.sup-app{
    background-color: rgb(222, 222, 228);
}
.sup-app h2{
    
    font-weight: 900;
    font-size: 35px;
	font-family: 'Poppins';
	font-style: normal;
	color: #200b03;
    line-height: 30px;
    
    
}
.sup-app span{
    color: blue;
}
.sup-app h3{
    font-weight: 900;
    font-size: 25px;
	font-family: 'Poppins';
	font-style: normal;
	color: #200b03;
    margin-left: 0;
   
    margin-bottom: 40px;
}




@media screen and (max-width: 480px) {
    .sup-app h3{
        font-weight: 900;
        font-size: 20px;
        font-family: 'Poppins';
        font-style: normal;
        color: #200b03;
        
        margin-bottom: 40px;
    }
    .sup-app h2{
    
        font-weight: 900;
        font-size: 25px;
        font-family: 'Poppins';
        font-style: normal;
        color: #200b03;
        line-height: 40px;
        
        
    }
}