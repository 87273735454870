.post-card {
    padding: 20px 1.5vw;
	box-shadow: 5px 4px 20px rgba(118, 118, 214, 0.36);
	border-radius: 10px;
	padding-bottom: 45px;
}
.post-card .img {
	border-radius: 8px;
	position: relative;
	background-color: rgb(194, 230, 241);
}

.post-card img{
	width: 100%;
	border-radius: 8px;
	height: 200px;
}

.post-card .title {
	font-family: "Manrope", sans-serif;
	font-style: normal;
	font-weight: 800;
	font-size: 16px;
	line-height: 25px;
	color: #000000;
    padding-top: 34px;
}

.post-card .description {
	font-family: "Manrope", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 25px;
	margin-bottom: 10px;
	color: rgba(0, 0, 0, 0.6);
}
@media(max-width:800px){
	.post-card {
		padding: 10px 10px;
        margin-top: 30px;
		padding-bottom: 45px;
	}
}
