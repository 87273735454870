.tophead h1{
    font-weight: 900;
    font-family: "Montserrat", sans-serif;
    color: black;
}

.tophead p{
    font-size: 18px;
    font-weight: 520;
    font-family: "Montserrat", sans-serif;
    color: rgb(20, 20, 22);
    margin-top: 25px;
}
@media(max-width:800px){
    .tophead h1{
        font-weight: 900;
        font-family: "Montserrat", sans-serif;
        color: black;
    }
    
    .tophead p{
        font-size: 18px;
        font-weight: 520;
        font-family: "Montserrat", sans-serif;
        color: rgb(20, 20, 22);
        margin-top: 16px;
    }
}