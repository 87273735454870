.contactus {
	font-family: "Poppins", sans-serif;
	font-style: normal;
	margin-top: 20px;
}
.contactus .form-contact{
	background: #ffffff;
	box-shadow: 0px 4px 25px rgba(104, 110, 255, 0.36);
	border-radius: 10px;
	padding: 40px;
	padding-bottom: 70px;
	margin-top: 20px;
	margin-left: 15px;
}
.contactus .map-contact h1 {
	font-family: "Poppins", sans-serif;
    font-size: 35px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 0em;
}
.contactus .map-contact  h3 {
	font-weight: 400;
	font-size: 18px;
	line-height: 28px;
	color: rgba(0, 0, 0, 0.6);
}
.contactus .map-contact  h4 {
	font-weight: 400;
	font-size: 17px;
	line-height: 28px;
	color: rgba(0, 0, 0, 0.6);
}
.contactus .map-contact img{
	width: 70%;
	height: 550px;
}
.contactus .form-contact h1{
	text-align: center;
	margin-bottom: 20px;
	font-family: "Poppins", sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0em;
}
.contactus .form-item {
    position: relative;
    margin-bottom: 15px; /* Adjust spacing between input fields */
}

.contactus .form-item:not(:nth-last-child(1))::before {
    content: "*";
    position: absolute;
    left: -14px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    color: #f0483e;
}


.contactus .form-contact label {
	font-weight: 400;
	font-size: 16px;
}
.contactus .form-contact input{
	height: 50px;
	border-radius: 8px;
}
.contactus .form-contact textarea{
	height: 80px;
	border-radius: 8px;
}
.contactus textarea::placeholder{
	font-size: 13px;
}
.contactus input::placeholder{
	font-size: 15px;
	
}
.reload-img{
	width: 30px;

}
.wrapper { 
    display: flex; 
    align-content: center; 
    margin: 1em 0; 
	
} 
#reload-button{
	border:none;
}
.captcha-box{
	border: 1px solid rgb(218, 208, 208);
	border-radius: 8px;
}
.contactus .form-contact .btn-primary{
	width: 150px;
	border-radius: 8px;
	
}
@media (max-width: 800px) {
	.contactus .map-contact img{
		width: 60%;
		height: 300px;
	}
	.contactus .form-contact input{
		height: 40px;
		border-radius: 8px;
	}
	.contactus .form-contact textarea{
		height: 60px;
		border-radius: 8px;
	}
	.contactus .form-contact{
		margin-left: 0px;
		padding-bottom: 30px;
	}
	
}
