
.qreachknowmore-tophead{
	position: relative;
	margin-top: 20px;
    background-color: rgb(207, 218, 228); 
    border-bottom-left-radius: 25px;
}
.qreachknowmore-tophead .qreach-content h1{
    margin-top: 65px;
    font-weight: 900;
    font-size: 35px;
	font-family: 'Poppins';
	font-style: normal;
	color: #060918;
    line-height: 55px;
}
.qreachknowmore-tophead .qreach-content h1 span{
    color: blue;
}
.qreachknowmore-tophead .qreach-content p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #0e0d0d;
	margin-top: 20px;
    margin-bottom: 50px;
}

.qreachknowmore-tophead .qreach-content button{
    border: none;
    background-color: blue;
    border-radius: 35px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 50px;
    padding-right: 50px;
    color: #ffff;
    margin-top: 40px;
    font-size: 18px;
    font-weight: 500;
    width: 250px;
}
