.brand-transformation h2 {
    font-weight: 900;
    font-size: 30px;
	font-family: 'Poppins';
	font-style: normal;
	color: black;
    line-height: 50px;
    margin-bottom: 20px;
    padding-top: 40px;
}
.brand-transformation h3 {
    font-weight: 900;
    font-size: 18px;
	text-align: center;
	font-style: normal;
	color: black;
    line-height: 50px;
    
   
}
.list-card{
    padding: 20px;
    padding-bottom: 0px;  
}

.brand-transformation ul {
    text-align: left;
    list-style-type: none;
    padding: 0;
    line-height: 30px;
}

.brand-transformation ul.negative li::before {
    content: '❌ ';
    color: red;
}

.brand-transformation ul.positive li::before {
    content: '✅ ';
    color: green;
}

.brand-transformation .vs {
    font-size: 20px;
    font-weight: bold;
    color: white;
    background-color: blue;
    border-radius: 50%;
    border: none;
    padding: 20px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
   
}
.vs:hover {
    background-color: darkblue;
    transform: scale(1.1);
    cursor: pointer;
}
@media (max-width: 800px) {
    .vs {
        margin-top: 20px;
    }
    .brand-transformation h2 {
        font-weight: 900;
        font-size: 25px;
        line-height: 35px;
        margin-bottom: 10px;
        padding-top: 20px;
    }
}