.discover-content h2{
    margin-top: 30px;
    font-weight: 900;
    font-size: 30px;
	font-family: 'Poppins';
	font-style: normal;
	color: #200b03;
    line-height: 50px;
   
    margin-bottom: 40px;
}
.discover-content .card-footer{
    background-color: white;
    border-top:none;
    
}
.discover-content .card-footer {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }
@media (max-width: 500px) {
   /* .discover-content  .quard{
        width: 50%;
        margin-top: 20px;
    }*/
    .custom-card .card-text {
        font-size: 14px;
        color: rgb(82, 76, 76);
        
    }
    .custom-card {
        margin-bottom: 40px; 
    }
   
}