.blogpost {
	font-family: "Montserrat", sans-serif;
	color: #000000;
}
.blogpost h1 {
	font-style: normal;
	font-weight: 800;
	font-size: 36px;
	line-height: 48px;
	
}
.blogpost p {
	font-style: italic;
	font-weight: 500;
	font-size: 18px;
	line-height: 28px;
}
.cover-img{
	width: 100%;
	left: 0;
	right: 0;
}
