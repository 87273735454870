.tophead-content .image-container-banner {
  position: relative;
  height: 100%;
  width: 100%;
}  
.tophead-content .prod-banner-image {
  height: 100%;
  width: 100%;
} 
.tophead-content .text-button-container {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}  
.tophead-content .image-text-banner {
  font-size: 18px; 
  margin-bottom: 20px;
  color: black;
  padding: 10px;
  margin-top: 5px;
  line-height: 22px;
}
.tophead-content .image-text-banner  span{
  color: blue;
}
.tophead-content  .banner-button {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 1.2rem; 
  border: none;
  border-radius: 5px;
  background-color: #3157EF; 
  color: #fff; 
  cursor: pointer;
}
.marquee-slider{
    margin-bottom: 40px;
}
.marquee-slider p{
    text-align: center;
}
.marquee-slider img {
    height: 108px;
    padding: 10px;
    filter: grayscale(100%);
    transition: filter 0.3s ease;
}
.marquee-slider img:hover {
    filter: grayscale(0%);
}
.image_wrapper {
    margin: 0px 50px;
}

@media (max-width: 800px) {
    .marquee-slider img {
        height: 100px;
        padding: 10px;
        filter: grayscale(100%);
        transition: filter 0.3s ease;
    }
    .marquee-slider img:hover {
        filter: grayscale(0%);
    }
    .digital{
        width: 30%;
        margin-left: 10px;
    }
    .digital h4{
        color: black;
    }
}
/* Tablets and larger phones */
@media screen and (max-width: 768px) {  
  .tophead-content .image-text-banner {
      font-size: 10px;
      padding: 8px;
    }
    #banner-button {
      padding: 8px 16px;
      font-size: 1rem;
    }
    .tophead-content  .text-button-container {
      width: 80%;
    }
  }
  
  /* Small phones */
  @media screen and (max-width: 480px) {
    .tophead-content .image-container-banner{
        height:180px;
    }
    .tophead-content .image-text-banner {
      font-size: 8px;
    }
    #banner-button {
      margin-top: -20px;
      font-size: 12px;
    }
    .tophead-content  .text-button-container {
      width: 90%;
    }
  }
