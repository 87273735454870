.usecaseslist{
    margin-top: 80px;
    margin-bottom: 80px;
}
.usecaseslist .post-card {
    padding: 20px 1.5vw;
	box-shadow: 0 2px 5px 7px #afadad;
	border-radius: 10px;
	padding-bottom: 20px;
}
.usecaseslist .post-card:hover{
   transform: translateY(-10px);
}
.usecaseslist .post-card .img {
	border-radius: 8px;
	position: relative;
	background-color: rgb(194, 230, 241);
}

.usecaseslist .post-card img{
	width: 100%;
	border-radius: 8px;
	height: 200px;
    box-shadow: 0 2px 5px 5px #afadad;
}

.usecaseslist .post-card .title {
	font-family: "Manrope", sans-serif;
	font-weight: 800;
	font-size: 20px;
	line-height: 25px;
	color: #000000;
    padding-top: 30px;
    padding-bottom: 20px;
}

.usecaseslist .post-card .description {
	font-family: "Manrope", sans-serif;
	font-weight: 400;
	font-size: 18.5px;
	line-height: 28px;
	color: black;
}
@media(max-width:800px){
	.usecaseslist .post-card {
		padding: 10px 10px;
        margin-top: 30px;
		padding-bottom: 45px;
	}
}
