.brand-difference h2{
    font-weight: 900;
    font-size: 30px;
	font-family: 'Poppins';
	font-style: normal;
	color: black;
    line-height: 50px;
    margin-bottom: 40px;
    padding-top: 40px;
}
.brand-difference span{
    color: blue;
}


.custom-card1 {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 3px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;   
}
.custom-card1:hover{
    transform: translateY(-10px);
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 3px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}
.custom-card1 .card-title {
    font-size: 16px;
    font-weight: 900;
    color: black;
    text-align: center;
}

.custom-card1.card-text {
    font-size: 14px;
    color: #6c757d;
    /*text-align: center;*/
    
}
.card-top {
    position: relative;
    top: -30px;
    margin-bottom: -30px;
    display: flex;
    justify-content: center;
}

.card-top img {
    height: 90px;
    width: 90px;
    border-radius: 50%;
    
    padding: 10px;
}

.brand-difference  .card-body {
    text-align: center;
}
.shadow:hover {
    transform: translateY(-10px);
    border-radius: 8px;
    box-shadow: 0px 3px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;

}
/*.card:hover{
    transform: translateY(-10px);
    border-radius: 8px;
    box-shadow: 0px 3px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}*/
.para{
    margin-bottom: 0;
}
@media (max-width: 500px) {
    .quard{
        width: 50%;
        padding: 20px;
    }
    .quard1{
        width: 70%;
        padding: 50px;
    }
  
}
