.qreachknowmore-solution{
    margin-top: 80px;
}
.qreachknowmore-solution  .solution-content h2{
    margin-top: 25px;
    font-weight: 900;
    font-size: 40px;
	font-family: 'Poppins';
	font-style: normal;
	color: blue;
    line-height: 55px;
}
.solution-content #solution-card {
    
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.2); /* Soft shadow */
    transition: 0.3s;
    background-color: rgb(193, 205, 236);
  }
  
  /* On mouse-over, add a deeper shadow */
.solution-content #solution-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
.solution-content #solution-cardcontent{
    padding: 30px;
}
.solution-content #solution-cardcontent h3{

    font-weight: 500;
    font-size: 28px;
	font-family: 'Poppins';
	font-style: normal;
	color: black;
    line-height: 55px;
}


.solution-content .feature-item {
    background-color: rgb(193, 205, 236);
    color: #000;
    padding: 20px;
   
    border-radius: 8px;
    font-size: 23px;
    font-weight: 500;
    font-family: Arial, sans-serif;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.solution-content .sol-img{
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2); /* Soft shadow */
    transition: 0.3s;
    width: 100%;
    height:100%
    
}
.solution-content #metrics-card {
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.2); /* Soft shadow */
    transition: 0.3s;
    background-color: rgb(193, 205, 236);
    text-align: center;
    padding: 30px 20px;
    border-radius: 8px;
    
    flex: 1;
}

.solution-content #metrics-card h4 {
    font-size: 30px;
    font-weight: bold;
    color: #0e61df; /* Bold purple text */
    margin: 0;
}

.solution-content #metrics-card p {
    font-size: 18px;
    color: #333;
    margin: 10px 0 0;
    font-weight: 500;
}
.solution-features{
    height: 95%;
}

/* Arrows Styling */
.solution-content .arrow-up, .arrow-down {
    color: green;
    font-size: 60px;
    margin-right: 5px;
}
@media (max-width: 768px) {
    .solution-content .sol-img{
       
        height:100%
        
    }
    .solution-row{
        flex-direction: column; /* Stack rows vertically */
        gap: 20px;
    }
    
    .solution-col1, .solution-col2 {
        width: 100%; /* Full width for each section */
       
    }
}





