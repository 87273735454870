.talkbusines .talkbusbox {
    background-color: #E8F7FF;
    border-radius: 12px;
    margin-top: 60px;
    text-align: left;
}

.talkbusines h1 {
    font-weight: 900;
    font-family: "Montserrat", sans-serif;
}

.talkbusines p {
    font-size: 18px;
    font-weight: 520;
    font-family: "Montserrat", sans-serif;
    color: rgb(20, 20, 22);
    margin-top: 20px;
}

.talkbusbox .contactus {
    background-color: #3157EE;
    width: 330px;
    height: 100%;
    padding: 10px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    margin-top: 0;

}

.captcha-wrapper {
    display: flex;
    align-content: center;
    margin: 1em 0;
    margin-left: 15px;

}

.invalid {
    border: 1px solid red;
}


.talkbusbox .contactus h3 {
    color: white;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    margin-top: 70px;
    margin-left: 40px;
}

.talkbusbox .contactus h6 {
    color: white;
    font-weight: 800;
    font-family: "Montserrat", sans-serif;
    margin-top: 20px;
    margin-left: 40px;
}

.talkbusbox .contactform {
    width: 760px;
    padding: 10px;
    text-align: left;
    margin-bottom: 70px;
}

.talkbusbox .contactform h2 {
    color: black;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    margin-top: 30px;
    margin-left: 10px;
}

.talkbusbox .contactform form {
    margin-top: 30px;
}

.talkbusbox .contactform input[type="file"] {
    border: 1px dashed black;
    margin-left: 10px;
    width: 97%;
}

.talkbusbox .contactform textarea {
    margin-left: 10px;
    width: 97%;
}

.talkbusbox .contactform #captcha-reload-button {
    width: 20px;
}


.talkbusbox .contactform button {
    border: none;
    color: white;
    background-color: #3157EE;
    width: 25%;
    padding: 10px;
    border-radius: 8px;
    margin-left: 10px;
    margin-top: 10px;
}

@media(max-width:800px) {
    .talkbusines .talkbusbox {
        /*background-color: #E2F4FF;*/
        border-radius: 12px;
        margin-top: 60px;
        text-align: left;
    }

    .talkbusbox .contactus {
        border-radius: 12px;
        width: 100%;
    }

    .talkbusbox .contactus h3 {
        margin-top: 15px;
    }

    .talkbusbox .contactus h6 {
        margin-top: 10px;
    }

    .talkbusbox .contactform {
        background-color: rgb(241, 248, 247);
        width: 100%;
        border-radius: 12px;
        margin-top: 20px;
    }

    .talkbusbox .contactform button {
        width: 90%;
    }

    /*.talkbusbox .contactform textarea {
        margin-left: 0;
    }*/
    .talkbusbox .contactform input[type="file"] {
        /*margin-left: 0;*/
        padding-left: 10px;
    }
    
   /* .talkbusbox .contactform .captcha-field input[type="text"] {
        margin-left: 0;
    }*/
    /*.captcha-wrapper {
        margin-left: 0;
    }*/
  

    /*.talkbusbox .contactform #user-input {
        margin-left: -5px;
    }*/
}

.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: wheat;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.spinner {
    border: 5px solid rgba(255, 255, 255, 0.3);
    border-top: 5px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}