.top-head .autom-top h1 {
	font-weight: 600;
	font-size: 47.8px;
	line-height: 56px;
}
.top-head .autom-top h4{
	margin-top: 35px;
}
.top-head .main-img1 img{
	margin-top: -110px;
}
@media(max-width:800px) {
	.top-head .autom-top h1 {
		margin-top: 10px;
		font-weight: 550;
		line-height: 50px;
		font-size: 35px;
	}
	.top-head .main-img1 img{
		margin-top: 0px;
	    width:108%
	}
	.top-head .customer-mapper h1 {
		margin-top: 0;
	}
	.top-head .main-img1{
		padding-top: 0px;
	}
	
}