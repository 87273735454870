.beyond-conent{
    margin-top: 50px;
}
.beyond-conent .sticky{
    position: fixed;
    top: 11%;
    background-color: white;
}
.beyond-conent .personal-contentbody ul{
    list-style: none;
}
.beyond-conent .personal-contentbody ul li{
    margin-top: 0px;
    color: #3F3F3F;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    margin-left: -30px;
}
.beyond-conent .personal-contentbody ul li a{
    text-decoration: none;
}
.beyond-conent .personal-contentbody p{
    font-family: Inter;
    margin-top: 10px;
    color:  #868E96;
    text-transform: uppercase;
    font-size: 18px;
    font-style: normal;
    font-weight: 540;
}
.beyond-conent .personal-contentbody hr{
    width: 80%;
}
.beyond-conent .personal-content2{
    padding-left: 75px;
}
.beyond-conent .personal-content2 b{
    color: #3F3F3F;
}
.beyond-conent  .personal-content2 h2{
    color: #3F3F3F;
    font-family: 'Montserrat';
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    line-height: 42px; 
    margin-bottom: 25px;
}
.beyond-conent .personal-content2 p{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    padding-bottom: 15px;
    color: #3F3F3F;
}
.beyond-conent .personal-content2 ul{
    list-style-type: square;
}
.beyond-conent .personal-content2 ul li{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    padding-bottom: 32px;
    color: #3F3F3F;
    margin-left: -10px;
}
.beyond-conent .image-position{
    text-align: left;
    margin-bottom: 60px;
}
.beyond-conent  .image-position img{
    width: 90%;
}

@media (max-width: 800px) {
    .beyond-conent .personal-content2{
        padding-left: 0px;
        padding-right: 10px;
    }
    .beyond-conent .personal-content2 p{
        font-weight: 500;
        font-size: 17px;  
        line-height: 30px;
    }
    .beyond-conent .personal-content2 h2{
        font-weight: 600;
        font-size: 25px;
        text-align: left;
        margin-top: 40px;
    }
    .beyond-conent .personal-content2 ul li{
        margin-left: -10px;
    }
    .beyond-conent  .image-position img{
        width: 100%;
    }
    .beyond-conent .sticky{
        position: relative;
        padding-bottom: 20px;
        top: 0%;
    }
}
