.brand-content{
    margin-top: 80px;
}
.brand-content h2{
    margin-top: 25px;
    font-weight: 900;
    font-size: 32px;
	font-family: 'Poppins';
	font-style: normal;
	color: #200b03;
    line-height: 50px;
    margin-bottom: 30px;
}
.brand-content span{
    color: #3157EF;
}
.brand-content .retail-content3{
    border: 2px solid black;
    padding: 20px;
    padding-top: 0px;
    border-radius: 5px;
    text-align: left;
    height: 650px;
    margin-top: 12px; 
}

.brand-content .retail-content3 h2{
    margin-top: 20px;
    font-weight: 900;
    font-size: 24px;
	font-family: 'Poppins';
	font-style: normal;
	color: #200b03;
    margin-bottom: 20px;
    line-height: 40px;
    text-align: center;
    
}
  
  /* Card styles */
  .brand-content .custom-card-retail {
    background-color: #fff;
    border: 2px solid black;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    transition: transform 0.3s ease;
  }
  
  .brand-content .custom-card-retail:hover {
    transform: translateY(-5px);
  }
  
  .brand-content .custom-card-retail .card-img-top {
    height: 250px;
    object-fit: cover;
    border-bottom: 1px solid #ddd;
  }
  
  .brand-content .custom-card-retail .card-body {
    padding: 20px;
    margin-bottom: 50px;
  }
  
  .brand-content .custom-card-retail .card-title {
    margin-top: 20px;
    font-weight: 900;
    font-size: 24px;
	font-family: 'Poppins';
	font-style: normal;
	color: #200b03;
    margin-bottom: 20px;
    line-height: 30px;
    text-align: center;
  }
  
  .brand-content .custom-card-retail .card-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: black;
	margin-top: 8px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  
  
 
@media (max-width: 800px) {
    .brand-content h2{
        font-size: 30px;
        line-height: 50px;
        text-align: center;
        
    }
    .brand-content .custom-card-retail .card-img-top {
        height: 200px;
        
      }
      .brand-content .custom-card-retail .card-title {
        font-size: 20px;
        line-height: 28px;
       
      }
      .brand-content .custom-card-retail .card-text {
        font-size: 16px;
      }
 
    
    
}

