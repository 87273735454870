.about-content {    
    padding: 50px 10vw;
 }
 .about-content h6{
     font-weight: 700;
     margin-left: 0;
     color: black;
     font-size: 18px;
 }
 .about-content h2{
     font-weight: 700;
     font-size: 35px;
     line-height: 45px;
     font-family: "Montserrat", sans-serif;
 }
 .about-content p{
     font-style: normal;
     font-weight: 540;
     font-size: 18px;
     line-height: 28px;
     color: #3F3F3F;
     margin-top: 30px;
 }
 .about-content span{
     color: blue;
 }
 .about-content hr{
     width: 200px;
     height: 3px;
     background-color: black;
     color: #000;
    
 }
 .about-content h5{
     font-weight: 700;
     margin-left: 0;
     color: black;
     font-size: 14px;
 }
 .about-content .shadow-right{
     background-color: transparent;
     text-align: center;
     position: relative;
     border: 1px solid blue;
     border-radius: 18px;
 }
 .about-content .shadow-right h6{
     font-size: 12.5px;
     text-align: center;
     line-height: normal;
     margin-top: 15px;
 }
 .about-content .shadow-right .team-image{
    width: 90px;
 }
 .about-content .our-value-card{
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
    margin-right: 10px;
    padding: 10px;
    height: 100%;
 }
 .about-content .our-value h5{
     font-weight: 800;
     margin-left: 0;
     color: black;
     font-size: 20px;
 }
 .about-content .our-value h6{
     font-weight: 700;
     margin-left: 0;
     color: black;
     font-size: 16px;
     margin-top: 25px;
 }
 .about-content .our-value p{
     font-size: 14px;
     text-align: left;
     font-style: italic;
     line-height: normal;
     color: black;
     margin: 0;
 }
 .success-story {
    margin: 20px 0;
  }
  
  .success-heading {
    font-size: 20px;
    font-weight: 800;
    
    
  }
  
  .success-text {
    margin-top: 0;
    font-size: 16px;
    line-height: 1.6;
  }
  
  .timeline-container {
    padding-left: 80px;
    margin-bottom: 50px;
  }
  
  .timeline-box {
    background-color: #b5d1ef;
    padding: 20px;
    border-radius: 16px;
    width: 100%;
  }
  
  .timeline-item {
    line-height: 30px;
  }
  
  .timeline-year {
    font-size: 16px;
    color: blue;
    margin: 0;
  }
  
  .timeline-box .timeline-description {
    font-size: 16px;
    margin: 0;
    margin-top: 10px;
  }
  
  .vertical-line {
    width: 2px;
    height: 20px;
    background-color: gray;
    margin: 10px 0;
  }
  
 .about-content .our-value hr{
    margin-bottom: 3px;
    margin-top: 6px;
    width: 100%;
 }
 
 .about-content .shadow-right p{
     font-size: 16.5px;
     text-align: left;
 }

 .vertical-line {
     height: 40px;
     width: 1px;
     border-left: 2px dotted #444;
     left: 50%;
     margin-left: 13px;
      
     
 }
 @media screen and (max-width:800px){
     .about-content h2{
         font-size: 25px;
         line-height: 40px;
     }
     .about-content p{
         font-size: 16px;
         line-height: 28px;
         font-weight: 500;
     }
 }
 @media screen and (min-width : 768px) {
    .about-content .shadow-right{
        height: 207px;
        margin-right: 20px;
     }
 }