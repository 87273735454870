.product{
    background-color: rgb(222, 222, 228);
}
.product .prod-digi-content h2{
    margin-top: 30px;
    font-weight: 900;
    font-size: 30px;
	font-family: 'Poppins';
	font-style: normal;
	color: #200b03;
    line-height: 50px;
    margin-bottom: 40px;
}
.product .prod-digi-content span{
    color: blue;
}
.product .product-body{
    padding-bottom: 20px;
}
.product .main-text{
    padding-bottom: 40px;
}
.product .prod-digi-content .card-header{
    background-color: white;
    border-bottom:none;
    font-size: 15px;
    font-weight: 900;
    color: black;
    text-align: center;
    padding-top: 10px;
    
}
.product .prod-digi-content .card-header {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }
.custom-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    
}

.custom-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.custom-card-img-top {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 200px;
    object-fit: cover;
}

.custom-card .card-title {
    font-size: 16px;
    font-weight: 900;
    
    color: black;
    text-align: center;
}

.custom-card .main-text {
    font-size: 16px;
    color: rgb(82, 76, 76);
    
}
.product-know-more-button {
    text-align: center; /* Centers the content inside the div */
    margin-top: 40px;
    margin-bottom: 0; /* Optional: Add spacing above the button */
}

.know-more-button {
    background-color: #3157EF; 
    color: white; 
    border: none;
    padding: 10px 40px; 
    border-radius: 5px; 
    font-size: 18px; 
    cursor: pointer; 
    transition: background-color 0.3s ease;
}

.know-more-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

@media (max-width: 500px) {
    
    .product .prod-digi-content h2{
        font-size: 20px; 
        line-height: 30px;
    }
       
}