.signup-content{
  box-shadow: 0 -5px 10px rgba(170, 169, 169, 0.2), /* Top shadow */
  5px 0 10px rgba(179, 177, 177, 0.2),  /* Right shadow */
  0 5px 10px rgba(153, 152, 152, 0.2);
  border-radius: 5px;
}
.signup-content .centered-content {
  align-items: center; 
  justify-content: center; 
  text-align: center;
  padding-top: 120px;
}
.signup-content .cipherlogo {
  width: 150px; 
  margin-bottom: 40px; 
}  
.signup-content .centered-content p{
 
  padding-left: 80px;
  padding-right: 50px;
} 
.signup-content hr.rounded {
  border-top: 4px solid #bbb;
  border-radius: 5px;
  width: 100px;
  text-align: center;
  margin-left: 250px;
  margin-right: 250px;
}
.signup_form h2{
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: 'Poppins';
}

.signup_form .select-container select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  font-size: 16px;
  color: #333;
  height: 45px;
  outline: none;
  transition: border-color 0.3s ease;
}
.signup_form  button{
  background-color: #3157EF; 
  color: white; 
  padding: 10px; 
  border: none; 
  border-radius: 5px;
  font-size: 1rem; 
  cursor: pointer; 
  width: 120px;
  text-align: center; 
  margin-top: 8px;
  margin-bottom: 25px;
}
.signup_form  button:hover {
  background-color: #3157EF; 
  transform: translateY(-2px); 
}
.signup_form .form-check-label{
  margin-top: 3px;
  margin-left: 8px;
}
.recaptcha-container {
  margin-top: 10px;
  margin-left: -23px;

}
.signup_form .form-check-label span{
  color:blue;
}


/* Small Button Styling */
.signup_form button {
  padding: 6px 12px;
  font-size: 14px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
 
}

/* Disabled Button Styling */
.signup_form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  pointer-events: none; /* Prevent hover events */
}

/* Button Hover Effect */
.signup_form button:not(:disabled):hover {
  background-color: #0056b3;  /* Darker blue */
}

.Toastify__toast-container {
  z-index: 9999;
}
@media(max-width:800px){
.signup-content .image-container{
  display: none;
}
}