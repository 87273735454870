
.top-head .top-content h1{
    font-size: 32px;
    font-family: 'Poppins';
    font-weight: 900;
    line-height: 47px;
    padding-right: 15%;
    margin-top: -12px;
}
.top-head .top-content h4{
    font-size: 16px;
    font-weight: 900;
}
.top-head .btn-BlueBg {
    width: 130px;
    height: 48px;
    margin-top: 30px;
}
.top-head .btn-borderBlue {
    width: 130px;
    height: 48px;
    margin-top: 30px;
    font-weight: 550;
    border: 2px solid blue;
    margin-left: 20px;
    color: blue;
}
.marquee-slider{
    margin-bottom: 40px;
}
.marquee-slider p{
    text-align: center;
    justify-content: center;
}
.marquee-slider img {
    height: 108px;
    padding: 10px;
    filter: grayscale(100%);
    transition: filter 0.3s ease;
}
.marquee-slider img:hover {
    filter: grayscale(0%);
}
.marquee-slider .image_wrapper {
    margin: 0px 30px;
  }
.marquee-slider .image_wrapper img {
    width: 80%;
  }
@media (max-width: 800px) {
    .top-content{
        margin-top: 50px;
        
    }
    .top-head{
        margin-top: 5px;
        padding-top: 20px;
    }
    .top-head .top-content h1{
        font-size: 28px;
        font-weight: 900;
        line-height: 40px;
        padding-right: 10%;
        margin-top: -12px;
    }
    .top-head img{
        margin-top: 15px;
    }
    .marquee-slider img {
        height: 100px;
        padding: 10px;
        filter: grayscale(100%);
        transition: filter 0.3s ease;
    }
    .marquee-slider img:hover {
        filter: grayscale(0%);
    }
    .digital{
        width: 30%;
        margin-left: 10px;
    }
    .digital h4{
        color: black;
    }
}


