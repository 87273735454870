.feedback-content1{
  align-items: center;
  justify-content: center;
}
.feedback-content1 .slide {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 8px;
    padding: 10px;
}

.feedback-content1 .feedback h2 {
    margin-top: 40px;
    font-weight: 900;
    font-size: 32px;
    font-family: 'Poppins';
    font-style: normal;
    color: #200b03;
    line-height: 50px;  
    margin-bottom: 45px;
}
.feedback-content1 .card-cont{
    box-shadow: 5px 4px  25px rgba(118, 118, 214, .36);
    border-radius: 10px;
}

.feedback-content1 .feedback span {
    color: #3157EF;
}
.feedback-content1 .content1{
   
    padding-top: 15px;
   
    box-shadow: 0px;
    padding-bottom: 0px;
  }
.feedback-content1 .content1  p{
   
    font-size: 18px;
    margin-top: 10px;
}
.feedback-content1 .content2 {
   border: none;
   height: 100%;
   padding: 20px;
   padding-bottom: 0px;
}
.feedback-content1 .slide-image {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-shadow: 5px 4px  25px rgba(118, 118, 214, .36);
}
.feedback-content1 .icon-text-container {
  display: flex;
  align-items: center;
}
.feedback-content1 .icon-text-container span {
    font-size: 25px;
  }
.feedback-content1 .highlight {
    color: #3157EF;
}
.feedback-content1 .author {
    font-weight: bold;
}
.feedback-content1 .slick-prev {
    left: -70px
}

.feedback-content1 .slick-next {
    right: -80px
}

.slick-prev:before,
.slick-next:before {
    font-size: 40px;
    color: rgb(28, 38, 189);
}
@media (max-width: 800px) {
    .feedback-content{
        width: 100%;
        align-items: center;
        justify-content: center;
    } 
}
@media (max-width: 500px) {
    .feedback-content{
        width: 100%;
        height: 90%;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    .slide-image {
        max-width: 100%;
        height: 100%;
        border-radius: 10px;
    }
    .slide {
        height: 55%;
    }
    .content1  p{
        text-align: justify;
        font-size: 16px;
      }
      .content1 {
        padding: 20px;
        padding-right: 0px;
      }
      .icon-text-container {
        display: flex;
        align-items: center;
      }
      .feedback-content h2 {
        text-align: center;
       
    }
    .content1 .quard{
        width: 50%;
    }
    
}