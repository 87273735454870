body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*overflow: auto;* /* Allow scrollbars */
  /*height: 100%;*/
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
}
.app-container {
  overflow-y: auto; 
  overflow-x: hidden; 
  height: 100vh; 
}
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 10px;
  right: 30px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 10px 14px #999;
  z-index: 100;
  padding-top: 5px;
}
.whatsapp-icon {
  margin-top: 20px;
}
@media(max-width:800px){
  .whatsapp_float{
    position: relative;
    display: none;
  }
}