.EnterpriseAccordian{
   padding-bottom: 60px;
   padding-top: 80px;
}
.crypto-ownership {
	position: absolute;
	width: 150px;
	height: 50px;
	background: #fefeff;
	box-shadow: 0px 4px 39px rgba(49, 87, 239, 0.19);
	border-radius: 5px;
	padding: 10px;
	top: 50px;
	right: 10%;
}

.crypto-ownership-item-1 {
	flex: 0 0 auto !important; 
	max-width: 25% !important;
}

.crypto-ownership-item-2 {
	flex: 0 0 auto !important; 
	max-width: 75% !important;
}

.p-4 {
    padding: 1.2rem!important;
}

.crypto-ownership h3 {
	display: inline-block;
	font-family: Manrope, sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 14px;
	/* or 108% */
	margin: 0;
	color: #3157ef;
}

.crypto-img2 {
	position: absolute;
	width: min(451px, 80%);
	height: 101px;
	background: #3157ef;
	box-shadow: 0px 4px 36px rgba(49, 87, 239, 0.05);
	border-radius: 8px;
	bottom: 50px;
	left: 8%;
}

.crypto-img2 h3 {
	font-family: Manrope, sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: min(20px, 2vw);
	margin-top: 5px;
	/* or 156% */
	display: inline-block;
	color: #ffffff;
}
.crypto-ownership2 h1{
	font-weight: 600;
	line-height: 50px;
	margin-top: 20px;
}
.crypto-ownership2 h3{
	font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
	margin-top: 20px;
}

@media (max-width: 800px) {
	.crypto-ownership {
		top: 80px;
		right: 3%;
	}

	.crypto-ownership h3 {
		font-weight: 600;
		font-size: 12px;
		line-height: 14px;
	}
	.crypto-img2 {
		width: min(451px, 80%);
		height: 50px;
		bottom: 20px;
		left: -10px;
	}
	.crypto-img2 h3 {
		font-family: Manrope, sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 9px;
		line-height: 14px;
		padding-bottom: 10px;
		/* or 156% */
		color: #ffffff;
		display:inline-block;
		margin-top: -25px;
		
	}
	.crypto-img2 .dollar-img{
		margin-top: -20px;
	}
}
