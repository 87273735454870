.qreachknowmore-visual{
    margin-top: 40px;
}
.qreachknowmore-visual  .visual-content h2{
    margin-top: 25px;
    font-weight: 900;
    font-size: 40px;
	font-family: 'Poppins';
	font-style: normal;
	color: rgb(3, 3, 3);
    line-height: 55px;
}
.qreachknowmore-visual  .visual-content span{
    color: blue;
}