.partner-content{
    margin-top: 30px;
}
.partner-content  .form-content h1{
    margin-top: 10px;
    font-weight: 900;
    font-size: 38px;
	font-family: 'Poppins';
	font-style: normal;
	color: #060918;
    line-height: 55px;
}
.partner-content  .form-content  span{
    color: blue;
}
.partner-content  .form-content1 {
    padding: 20px;
    box-shadow: 5px 4px 25px rgba(118, 118, 214, 0.36);
}
.partner-content  .form-content1  h2{
    font-weight: 600;
    font-size: 25px;
	font-family: 'Poppins';
	font-style: normal;
	color: #060918;
    line-height: 55px;
}
.partner-content  .form-content1 #buttonsubmit{
    background-color: #ff4c4c; 
    color: white; 
    padding: 12px 24px; 
    border: none; 
    width:200px;
    border-radius: 5px; 
    font-size: 16px;
    font-weight: bold; 
    cursor: pointer; 
    transition: background-color 0.3s ease; 
}
.error {
    color: red !important;
    font-size: 0.9rem;
    margin-top: -10px;
    margin-bottom: 10px;
    display: block;
}
.partner-content .form-item:not(:nth-last-child(3))::before {
    content: "*";
    position: absolute;
    left: -14px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    color: #f0483e;
}
#selecting-box .form-select {
    width: 100%; 
    max-width: 300px;
    border-radius: 5px; 
    padding: 8px; 
    font-size: 1rem; 
    color: rgb(10, 10, 10);
    margin-top: 3px;
}
#selecting-box .form-select:focus {
    border-color:rgb(185, 179, 179);
    box-shadow: none
}



@media (max-width: 800px) {
  
    .partner-content  .form-content{
      margin-top: 30px;
    }
    .partner-content  .form-content img{
        margin-bottom: 30px;
    }
    .partner-content  .form-content h1{   
        font-size: 30px;  
    }
    #selecting-box .form-select {
        height: 45px;
        
    }
    
   
}