.potential{
    background-color: rgb(222, 222, 228);
    
}
.potential h2{
   padding-top: 40px;
    font-weight: 900;
    font-size: 30px;
	font-family: 'Poppins';
	font-style: normal;
	color: #200b03;
    line-height: 50px;
    margin-bottom: 40px;
}
.potential span{
    color: blue;
}
.custom-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    
}

.custom-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.custom-card .top-card-img {
   
    height: 170px;
    object-fit: cover;
    width: 100%;
}

.custom-card .card-title {
    font-size: 16px;
    font-weight: 900;
    
    color: black;
    text-align: center;
}
.potential .card-body{
    text-align: center;
}
.custom-card .card-text {
    font-size: 16px;
    color: rgb(82, 76, 76);
    
    
}
@media (max-width: 500px) {
    .potential-content .quard{
        width: 50%;
        margin-top: 20px;
    }
}