.retail-tophead{
	position: relative;
}
.retail-tophead .retail-content h1{
    margin-top: 25px;
    font-weight: 900;
    font-size: 32px;
	font-family: 'Poppins';
	font-style: normal;
	color: #060918;
    line-height: 55px;
}
.retail-tophead .retail-content p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: #0e0d0d;
	margin-top: 20px;
}
.retail-tophead .retail-content input{
    padding-top:15px;
    padding-bottom: 15px;
    border-color: #b3b0b0;
    border-radius: 15px;
}
.retail-tophead .retail-content input::placeholder{
    font-size: 16px;
    color: #b3b0b0;
}
.retail-tophead .retail-content button{
    border: none;
    background-color: blue;
    border-radius: 35px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 50px;
    padding-right: 50px;
    color: #ffff;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 500;
}
.retail-tophead .highlight-content{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
   
}
.retail-tophead .highlight-content .tab-container {
    display: flex;
    align-items: center;
    background: white;
    
    border-radius: 20px;
    box-shadow: 1px 7px 8px 7px rgba(0, 0, 0, 0.1);
}

.retail-tophead .highlight-content .tab {
    text-decoration: none;
    color: black;
    font-weight: 500;
    padding: 10px 20px;
    text-align: center;
}

.retail-tophead .highlight-content .separator {
    width: 2px;
    height: 30px;
    background-color: black;
    margin: 0 10px;
}


.retail-tophead .highlight-content button{
    border: none;
    background-color: white;
    font-size: 18px;
    text-align: center;
    padding: 10px;
    border-radius: 50px;
    transition: background-color 0.3s ease;
}
.retail-tophead .highlight-content button:hover {
    background-color: lightgrey; 
}
.retail-tophead .highlight-content button.clicked:hover {
    background-color: skyblue; 
}

@media (max-width: 800px) {
    .cover-image{
        height: 90px;
    }
    .retail-tophead{
        position: relative;
        margin-top: 50px;	
       
    }
    .retail-tophead .retail-content h2{
        font-weight: 800;
        font-size: 30px;
        line-height: 40px;
        margin-top: 30px;
    }
    .retail-tophead .retail-content p{
        font-size: 18px;
        line-height: 28px;
    }
    .retail-tophead .retail-content button{
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 25px;
        padding-right: 25px;
        font-size: 17px; 
        margin-left: -5px;
    }
    .custom-button {
    background-color: #007bff; /* Primary color */
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }

  .custom-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
    transform: scale(1.05); /* Slight zoom on hover */
  }

  .vertical-line {
    width: 2px;
    height: 50px;
    background-color: #ddd; /* Line color */
    margin: auto;
  }

    .retail-tophead .retail-content img{
        
        height: 60%;
        width: 100%;
    }
    .retail-tophead .highlight-content{
        display: none;
    }
    
}
