.employee-content{
    background-color: white;
    padding-bottom: 50px;
    padding-top: 40px;
}
@media(max-width:800px){
    .employee-content{
        background-color: white;
        margin-top: 50px;
        padding-top: 50px;
        padding-bottom: 120px;
    
    } 
}
.employee-content h1{
    font-weight: 900;
    font-family: "Montserrat", sans-serif;
}
.employee-content ul li{
    font-size: 19px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    color: rgb(20, 20, 22);
    margin-left: -10px;
    margin-top: 8px;
}
.employee-content p{
    font-size: 18px;
    font-weight: 520;
    font-family: "Montserrat", sans-serif;
    color: rgb(20, 20, 22);
    margin-top: 20px;
}
.employee-content .employeebox{
    background-color: #E2F4FF;
    margin-top: 12px;
    display: flex;
    padding: 10px;
}
.employee-content .employeebox img{
    height: 50px;
    width: 50px;
}
.employee-content .employeebox p{
    font-size: 16px;
    margin-left: 15px;
    font-weight: 520;
    font-family: "Montserrat", sans-serif;
    color: rgb(20, 20, 22);
}