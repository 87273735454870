
.discover .discover-content h2{
    margin-top: 30px;
    font-weight: 900;
    font-size: 30px;
	font-family: 'Poppins';
	font-style: normal;
	color: #200b03;
    line-height: 50px;
   
    margin-bottom: 40px;
}
.discover span{
    color: blue;
}
.custom-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    
}

.custom-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.custom-card-img-top {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 200px;
    object-fit: cover;
}

.custom-card .card-title {
    font-size: 16px;
    font-weight: 900;
    
    color: black;
    text-align: center;
}

.discover-content .custom-card .card-text {
    font-size: 16px;
    color: rgb(82, 76, 76);
    text-align: left;
}
@media (max-width: 500px) {
  
    .custom-card .card-text {
        font-size: 14px;
        color: rgb(82, 76, 76);
       
    }
    .custom-card {
        margin-bottom: 20px; 
    }
  
   
}