.react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  .react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 70px;
    height: 35px;
    background: rgb(230, 228, 228);
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
  }
  
  .react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 31px;
    height: 31px;
    border-radius: 45px;
    transition: 0.2s;
    background:  blue;
    box-shadow: 0 0 2px 0 blue;
  }
  
  .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-90%);
  }
  
  .react-switch-label:active .react-switch-button {
    width: 60px;
  }
  /* facebook */
  .react_checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  .react_label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 60px;
    height: 28px;
    background: rgb(230, 228, 228);
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
  }
  
  .react_label .react_button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 25px;
    height: 25px;
    border-radius: 45px;
    transition: 0.2s;
    background:  blue;
    box-shadow: 0 0 2px 0 blue;
  }
  
  .react_checkbox:checked + .react_label .react_button {
    left: calc(100% - 2px);
    transform: translateX(-90%);
  }
  
  .react_label:active .react-switch-button {
    width: 60px;
  }
/* Twitter */
  .checkbox_twitter {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  .label_twitter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 60px;
    height: 28px;
    background: rgb(230, 228, 228);
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
  }
  
  .label_twitter .button_twitter {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 25px;
    height: 25px;
    border-radius: 45px;
    transition: 0.2s;
    background:  blue;
    box-shadow: 0 0 2px 0 blue;
  }
  
  .checkbox_twitter:checked + .label_twitter .button_twitter  {
    left: calc(100% - 2px);
    transform: translateX(-90%);
  }
  
  .label_twitter:active .switch_twitter {
    width: 60px;
  }
/* instagram */
.checkbox_instagram{
  height: 0;
  width: 0;
  visibility: hidden;
}

.label_instagram {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 60px;
  height: 28px;
  background: rgb(230, 228, 228);
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
}

.label_instagram .button_instagram {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 25px;
  height: 25px;
  border-radius: 45px;
  transition: 0.2s;
  background:  blue;
  box-shadow: 0 0 2px 0 blue;
}

.checkbox_instagram:checked + .label_instagram .button_instagram {
  left: calc(100% - 2px);
  transform: translateX(-90%);
}

.label_instagram:active .button_instagram {
  width: 10px;
}