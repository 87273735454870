.blogpost {
	font-family: "Montserrat", sans-serif;
	color: #000000;
}
/*hr.blog-line{
	border:10px solid black;
}*/

.blogpost h1 {
	font-style: normal;
	font-weight: 800;
	font-size: 30px;
	line-height: 48px;
	
	text-align: center;
}
.blogpost h2 {
	font-style: normal;
	font-size: 22px;
	line-height: 48px;
	text-align: center;
}


.blogpost p {
	font-style: italic;
	font-weight: 500;
	font-size: 18px;
	line-height: 28px;
	text-align: center;
}

.cover-img {
	width: 100%;
	left: 0;
	right: 0;
}

.px-magic {
	padding: 0 10vw;
}

@media (max-width: 500px) {
	.px-magic {
		padding: 0 3vw;
	}
}
