body::-webkit-scrollbar {
  display: none;
}

#root {
    overflow-x: hidden; 
}

.big-letter {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 28px;
}

.blog-content{
    padding: 50px 14vw;
}

.blog-content p{
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;

    padding-bottom: 15px;
    color: rgba(0, 0, 0, 0.6);
}
.blog-content ul li{
    margin-left: 20px;
}

.blog-content h2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 41px;

    padding-bottom: 7px;
}

.blog-content .img-wrap {
    border: 2px solid rgb(49, 87, 239);
    background: #FFFFFF;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 34px;
}

.img-wrap .main-img {
    max-width: 100%;
}

.blog-content li{
    color: #3157EF;
    line-height: 36px;
}

.blog-content li>span{
    color: black;
}

.blog-content .blog-author img {
    width: 70.57px;
    height: 70.57px;
}

.blog-content .blog-author p{
    padding: 0;
    margin-bottom: 0;
    color: black;
}

.blue-line {
    position: absolute;
    top: 50px;
    left: -1vw;
}

.blue-div {
    position: absolute;
    width: 200vw;
    height: 50%;
    left: -50vw;
    top: 50%;
    transform: translate(0, -50%);
    z-index: -1;
    background: #F4FAFE;
}

@media(max-width:1000px){
	.blog-content{
        padding: 50px 5vw;
    }

    .blue-line {
        left: -13vw;
    }
}

