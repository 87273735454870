
.thingdcando {
  padding: 80px 35px;
}

.things-item {
  border-radius: 8px;
}

.things-item p{

  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  /* or 156% */
  color: white;

}

.things-item.active {
  background: rgba(63, 63, 63, 0.48);
}

.things-item:hover {
  background: rgba(63, 63, 63, 0.48);

}

@media(max-width:800px){

}