.feedback-content1{
  width: 70%;
  align-items: center;
  justify-content: center;
}
.feedback-partner h2 {
    margin-top: 35px;
    font-weight: 900;
    font-size: 32px;
    font-family: 'Poppins';
    font-style: normal;
    color: #200b03;
    line-height: 50px;
    text-align: center;
    margin-bottom: 40px;
}

.feedback-partner span {
    color: #3157EF;
}
.card {
   border: none;
   height: 100%;
}

.content2{
  padding: 10px;
  text-align: center;
  
}

.content1  p{
 
  font-size: 18px;
  margin-top: 10px;

}
.icon-text-container {
  display: flex;
  align-items: center;
  
}

.slide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 290px;
    background-color: #fff;
    border-radius: 8px;
    padding: 10px;
}


.highlight {
    color: #3157EF;
}

.author {
    font-weight: bold;
}

.slide-image {
    max-width: 100%;
    height: 100%;
    border-radius: 10px;
    box-shadow: 5px 4px  25px rgba(118, 118, 214, .36);
}

.slick-prev {
    left: -50px
}

.slick-next {
    right: -40px
}

.slick-prev:before,
.slick-next:before {
    font-size: 40px;
    color: rgb(28, 38, 189);
}
@media (max-width: 800px) {
    .feedback-content{
        width: 100%;
        align-items: center;
        justify-content: center;
    } 
}
@media (max-width: 500px) {
    .feedback-content{
        width: 100%;
        height: 100%;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    .slide-image {
        max-width: 100%;
        height: 100%;
        border-radius: 10px;
    }
    .slide {
        height: 55%;
    }
    .content1  p{
       
        font-size: 16px;
      }
      .content1 {
        padding: 20px;
        padding-right: 0px;
      }
      .icon-text-container {
        display: flex;
        align-items: center;
      }
      .feedback-content h2 {
        text-align: center;
       
    }
    .content1 .quard{
        width: 50%;
    }
    
}