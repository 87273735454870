.homeapp-top .homeapp-content h1{
    font-size: 49px;
    font-weight: 600;
    line-height: 62px;
    letter-spacing: 0em;
    text-align: left;
	margin-top: 35px;
}
.homeapp-top .homeapp-content p{
	font-size: 19px;
	font-weight: 500;
	line-height: 28px;
	letter-spacing: 0em;
	text-align: left;
	margin-top: 25px;
}
.homeapp-top img{
	width: 100%;
	text-align: right;
}
@media(max-width:800px) {
	
	.homeapp-top .homeapp-content h1 {
		font-weight: 550;
		line-height: 40px;
		font-size: 30px;
		margin-top: 0px;
	}
	.homeapp-top .homeapp-content p{
		margin-top: 20px;
		text-align: left;
		font-size: 17px;
	}
	.homeapp-top img{
		margin-top: 15px;
	}
	
}