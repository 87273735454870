.tophead{
  text-align: center;
}
.tophead h1{
  margin-top: 150px;
  font-weight: 800;
}
.tophead p{
  color: black;
  font-weight: 500;
  font-size: 17px;
  margin-top: 10px;
  margin-bottom: 250px;
}
.brandwiseqrbox{
  background-color: white;
  position: relative;
  top: -150px;
  border-radius: 12px;
}
.brandwiseqrbox .nav{
  padding: 10px;
  padding-top: 20px;
  border-radius: 12px;
}
 .qr_logo{
  display: inline-block;
  padding: 0px 18px;
  height: 40px;
  font-size: 15px;
  line-height: 25px;
  color: blue;
  background-color: none;
  border: 1px solid blue;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 8px;
  text-transform: uppercase;
}
.qr_logo:hover{
  background-color: blue;
  color: #ffffff;
}

.qr_logo input[type="file"]{
  display: none;
}


.qr_data{
  text-align: left;
  margin-left: 40px;
  margin-bottom: 100px;
  margin-top: 60px;
}
.qr_data ul li{
  overflow: hidden;
  display: inline;
  margin-left: 20px;
}
.qr_data select{
   width: 100%;
   height: 37px;
   border: 1.8px solid rgb(212, 212, 211);
   background-color: #ffff;
   border-radius: 6px;
   padding-left: 8px;
}
.shorturl input{
  width: 100%;
  border-radius: 8px;
}
.qr_data .result{
  display: flex;
  border: 1.8px solid rgb(230, 230, 226);
  width: 100%;
  height: 40px;
  justify-content: space-between;
  border-radius: 8px;
}
.qr_data .result p{
  margin-left: 20px;
  margin-top: 5px;
  font-size: 18px;
  font-weight: 400;
}
.qr_data .result button{
  border: none;
  background: none;
  margin-right: 8px;
  font-size: 18px;
}
@media (max-width:800px) {
  .shorturl input{
    width: 100%;
    border-radius: 12px;
  }
  .qr_data{
    margin-left: 20px;
  }
  .qr_data .result{
    display: flex;
    border: 1.8px solid rgb(230, 230, 226);
    width: 100%;
    height: 40px;
    justify-content: space-between;
    border-radius: 12px;
  }
 
  
  .qr_data .custo_item ul {
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }
}
.qr_data p{
  margin-top: 30px;
  font-size: 12px;
}
.qr_data label{
  font-weight: 550;
  margin-left: 5px;
  margin-top: 5px;
}
.qr_data input::placeholder{
  font-size: 12px;
}
.qr_data h5{
  margin-top: 40px;
  font-weight: 550;
}
.qr_data .custo_item{
  text-align: left;
}
.qr_data .custo_item ul{
  margin-left: -50px;
}

.qr_data .custo_item a{
  border:1px solid rgb(110, 108, 108);
  border-radius: 22px;
  background-color: white;
  font-size: 14px;
  padding: 10px;
  text-decoration: none;
}
.qr_data input[type="color"]{
  height: 50px;
  width: 170px;
  border: 2px solid rgb(196, 194, 194);
  padding: 5px;
  margin-top: 20px;

}
.qr_data h4{
  font-weight: 600;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.qr_data .custo_item a.active{
  border: none;
  background-color: blue;
  color: #ffffff;
  text-decoration: none;
  padding: 10px;
  font-size: 15px;
  border-radius: 22px;
}
.qr_code .qrcodeimg{
   border: 1px solid rgb(153, 153, 156);
   height: 310px;
   width: 320px;
   padding: 10px;
   padding-top: 0px;
   margin-top: 60px;
   margin-left: 0px;
}
.qr_code p{
  margin-top: 20px;
}
.qr_data h6{
  margin-left: 85px;
  margin-top: -25px;
}

.social_item ul{
  display: inline;
  overflow: hidden;
 margin-left: -25px;
}
.qr_code button{
  background-color: blue;
  border: none;
  color: white;
  margin-top: 10px;
  padding: 8px;
  font-size: 17px;
  border-radius: 10px;
  width: 320px;
  text-align: center;
  margin-bottom: 70px;
  margin-left: 0px;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  
  
}


.qr_file{
  display: inline-block;
  padding: 0px 18px;
  height: 40px;
  font-size: 15px;
  line-height: 25px;
  color: black;
  background-color: none;
  border: 1px solid black;
  cursor: pointer;
  margin-top: 0px;
  border-radius: 8px;
  text-transform: uppercase;
}
.qr_file:hover{
  background-color: blue;
  border: none;
  color: #ffffff;
}

.qr_file input[type="file"]{
  display: none;
}
.brandwiseqrbox .nav {
  height: 50px;
  width: 100%;
  background-color: white;
  position: relative;
}

.brandwiseqrbox .nav > .nav-btn {
  display: none;
}

.brandwiseqrbox .nav > .nav-links {
  display: inline;
  float: right;
  font-size: 16px;
  margin-left: 0px;
}

.brandwiseqrbox .nav > .nav-links > a {
  display: inline-block;
  padding: 13px 10px 13px 10px;
  text-decoration: none;
  margin-left: 30px;
}

.brandwiseqrbox .nav > .nav-links > a:hover {
  background-color: blue;
  border-radius: 12px;
  color: white;
  padding: 8PX;
}
.brandwiseqrbox .nav > .nav-links > a.active{
  background-color: blue;
  border-radius: 12px;
  color: white;
  padding: 8px;
}
.brandwiseqrbox .nav > #nav-check {
  display: none;
}

@media (max-width:800px) {
  .tophead h1{
    margin-top: 150px;
    font-weight: 800;
    margin-left: 20px;
  }
  .tophead p{
   margin-left: 20px;
  }
  .brandwiseqrbox{
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    text-align: left;
    width: 100%;

  }
  .qr_data .custo_item a.active{
    border: none;
    background-color: blue;
    color: #ffffff;
    text-decoration: none;
    padding: 5px;
    font-size: 13px;
    border-radius: 22px;
  }
  .brandwiseqrbox .nav > .nav-btn {
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
    left: 0;

  }
  .brandwiseqrbox .nav > .nav-btn > label {
    display: inline-block;
    width: 100px;
    height: 50px;
    background-color: blue;
    color: white;
    margin-top: 5px;
    margin-left: 20px;
    padding: 13px;
  }
  .brandwiseqrbox .nav > .nav-btn > label:hover,.nav  #nav-check:checked ~ .nav-btn > label {
    background-color: black;
    color: white;

  }
  .brandwiseqrbox .nav > .nav-links {
    position: absolute;
    display: block;
    width: 100%;
    background-color: white;
    height: 0px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 50px;
    left: 0px;
  }
  .brandwiseqrbox .nav > .nav-links > a {
    display: block;
    width: 100%;
    margin-top: 15px;
    padding: 10px;
  }
  .brandwiseqrbox .nav > .nav-links > a:hover {
    color: blue;
    background-color: white;
  }
  .brandwiseqrbox .nav > .nav-links > a.active{
    color: rgb(255, 94, 0);
    padding: 8px;
    background-color: white;
  }
  .brandwiseqrbox .nav > #nav-check:not(:checked) ~ .nav-links {
    height: 0px;
  }
  .brandwiseqrbox .nav > #nav-check:checked ~ .nav-links {
    height: calc(100vh - 50px);
    overflow-y: auto;
    width: 110%;
    margin-left: -20px;
    z-index: 10;
  }
}
.qr_code .qrcodeimg .watermark{ 
  height: 40px;
  width: 115px;
  margin-top: -50px;
}
@media (max-width:800px) {
  .qr_code .qrcodeimg{
    border: 1px solid rgb(98, 98, 100);
    height: 303px;
    width: 320px;
    padding: 10px;
    padding-top: 0px;
    margin-top: 0px;
    text-align: center;
    margin-left: 20px;
 }
 .qr_code p{
  margin-left: 50px;
 }
 .qr_code button{
  background-color: blue;
  border: none;
  color: white;
  margin-top: 0px;
  padding: 8px;
  font-size: 17px;
  border-radius: 10px;
  width: 320px;
  text-align: center;
  margin-bottom: 20px;
  margin-left: 20px;
}
.qr_data .custo_item a{
  border:1px solid rgb(110, 108, 108);
  border-radius: 22px;
  background-color: white;
  font-size: 8px;
  text-decoration: none;
}
.qr_data .custo_item ul {
  flex-direction: column;
  gap: 2px;
  width: 140%;
}

}
.text-start input{
  border-radius: 15px;
}
.text-start label{
  font-size: 15px;
  font-weight: 400;
  margin-left: 8px;
}
.text-start input::placeholder{
  font-size: 12px;
}
.text-start button{
  font-size: 15px;
  font-weight: 400;
  border-radius: 18px;
}

