.customer-mapper {
	margin-bottom: -150px;
}

.customer-mapper h1 {
	font-weight: 700;
	font-size: 28px;
	line-height: 41px;
	margin-top: 10px;
	margin-bottom: 60px;
}

.customer-mapper .wrapper {
	width: 90%;
	height: 100%;
	background: #ffffff;
	box-shadow: 0px 4px 76px rgba(0, 0, 0, 0.06);
	border-radius: 9px;
	padding-bottom: 30px;
	display: block;
}

.customer-mapper .icon {
	padding-bottom: 10px;
}

.customer-mapper .title {
	font-weight: 800;
	font-size: 16px;
	line-height: 25px;
	margin-bottom: 18px;
}

.customer-mapper p {
	font-weight: 600;
	font-size: 16px;
	line-height: 25px;
	color: #666666;
	opacity: 0.8;
}
.customer-mapper .row{
    row-gap: 30px;
}

@media (max-width: 768px) {
	.customer-mapper .wrapper {
		width: 100%;
    	padding-bottom: 0;
	}

    .customer-mapper {
	    margin-bottom: 50px;
		margin-top: 0;
    }
	.customer-mapper h1 {
		font-weight: 700;
		font-size: 28px;
		line-height: 41px;
		margin-top: 0;
	
		
	}
}
