.boost-content {
    margin-top: 60px;
}

.boost-content .footwear-content2 h2{
    font-weight: 900;
    font-size: 32px;
	font-family: 'Poppins';
	font-style: normal;
	color: #200b03;
    line-height: 50px;
    margin-bottom: 40px;
}
.boost-content .footwear-content2 span{
    color: #3157EF;
}

.boost-content .footwear-content2 p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 32px;
    color: black;
}

.boost-content .footwear-content2 ul{
    list-style-type: square;
}
.boost-content .footwear-content2 .list-content{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 28px;
    color: black;
	margin-top: 5px;
    margin-left: -10px;
}
@media (max-width: 800px) {
    .boost-content .footwear-content2 h2{
        font-size: 32px;
        margin-bottom: 30px;
    }
    .boost-content .footwear-content2 p{
        font-size: 16px;
        margin-bottom: 30px;
    }
    .boost-content .footwear-content2 .list-content{
        
        font-size: 16px;
        
    }
}