.customer-mapper {
	position: relative;
}
.customer-mapper h1 {
	font-weight: 700;
	font-size: 28px;
	line-height: 41px;
	margin-top: 80px;
	margin-bottom: 60px;
	text-align: center;
}
.customer-mapper .wrapper{
	width: 90%;
	height: 100%;
	background: #ffffff;
	box-shadow: 10px 6px 80px rgba(0, 0, 0, 0.06);
	border-radius: 9px;
	padding-bottom: 30px;
	display: block;
}
.customer-mapper .wrapper .icon{
	padding-bottom: 10px;
}
.customer-mapper .wrapper .title{
	font-weight: 800;
	font-size: 16px;
	line-height: 25px;
	margin-bottom: 18px;
}
.customer-mapper  .wrapper p{
	font-size: 16px;
	line-height: 25px;
	font-weight: 500;
	color: #666666;
    letter-spacing: 0em;
    text-align: left;
}
.customer-mapper .row{
    row-gap: 30px;
}
@media (max-width: 800px) {
	.customer-mapper {
	    margin-bottom: 40px;
    }
	.customer-mapper h1 {
		font-size: 22px;
		line-height: 35px;
		margin-top: 0px;
		text-align: center;
		margin-bottom: 0px;
	}
	.customer-mapper .wrapper {
		width: 100%;
    	padding-bottom: 0;
		margin-top: 0px;
	}
}
