.seamless-content{
    margin-top: 80px;
}
.seamless-content  .packaging-content2 h2{
    margin-top: 25px;
    font-weight: 900;
    font-size: 40px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-style: normal;
	color: #200b03;
    line-height: 55px;
}
.seamless-content  .packaging-content2 p{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 32px;
    color: #3F3F3F;
	margin-top: 20px;
}
.seamless-content  .packaging-content2  ul{
    list-style-type: square;
}
.seamless-content  .packaging-content2  ul li{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 28px;
    color: #3F3F3F;
	margin-top: 5px;
    margin-left: -15px;
}
@media (max-width: 800px) {
    .seamless-content  .packaging-content2 h2{
        font-weight: 800;
        font-size: 30px;
        line-height: 40px;
        margin-top: 15px;
    }
    .seamless-content  .packaging-content2 p{
        font-size: 18px;
        line-height: 28px;
    }
    .seamless-content  .packaging-content2 ul li{
        font-size: 18px;
        line-height: 28px;
    }
     
  
}