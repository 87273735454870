.utilise-content{
    margin-top: 80px;
}
.utilise-content h2{
    margin-top: 25px;
    font-weight: 900;
    font-size: 35px;
	font-family: 'Poppins';
	font-style: normal;
	color: #200b03;
    line-height: 50px;
    margin-bottom: 30px;
}
.utilise-content span{
    color: #3157EF;
}
.utilise-content .footwear-content3{
    border: 2px solid black;
    padding: 14px;
    padding-top: 0px;
    border-radius: 5px;
    text-align: left;
    height: 650px;
    margin-top: 12px; 
}




  /* Card styles */
  .utilise-content .custom-card-footwear {
    background-color: #fff;
    border: 2px solid black;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    transition: transform 0.3s ease;
    
  }
  

  
  .utilise-content .custom-card-footwear  .card-img-top {
    height: 250px;
    object-fit: cover;
    border-bottom: 1px solid #ddd;
  }
  
  .utilise-content .custom-card-footwear .card-body {
    padding: 30px;
    margin-bottom: 50px;
    
    
  }
  
  .utilise-content .custom-card-footwear  .card-title {
    margin-top: 10px;
    font-weight: 900;
    font-size: 27px;
	font-family: 'Poppins';
	font-style: normal;
	color: #200b03;
    margin-bottom: 10px;
    line-height: 40px;
    text-align: center;
  }
  
  .utilise-content .custom-card-footwear  .card-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: black;
	margin-top: 8px;
    text-align: center;
  }
  
 
  

@media (max-width: 800px) {
    .utilise-content h2{
        font-size: 30px;
        line-height: 50px;
        text-align: center;
        
    }
    .utilise-content .custom-card-footwear  .card-img-top {
        height: 200px;
        object-fit: cover;
       
      }
      .utilise-content .custom-card-footwear  .card-text {
        font-size: 16px;
      }
      .utilise-content .custom-card-footwear  .card-title {
        font-size: 20px;
        line-height: 28px;
      }
   
    
    
}

