.bluedesign-bg {
	background-color: #3157ef;
	height: 294px;
	border-radius: 15px;
}

.bluedesign-bg h2 {
	font-family: Montserrat, sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 28px;
	line-height: 41px;
	/* or 146% */

	text-align: center;

	color: #ffffff;
}

.bluedesign-bg button {
	font-family: Manrope, sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 25px;
	/* identical to box height */
	color: #3157ef;
	width: 204px;
	height: 55px;
	background: #f8f2f2;
	border: 1px solid transparent;
	border-radius: 5px;
}

@media (max-width: 800px) {
	.bluedesign-bg {
		border-radius: 15px;
		height: 350px;
	}

	.bluedesign-bg h1 {
		font-size: 23px;
		line-height: 30px;
	}

	.bluedesign-bg button {
		width: 176px;
		height: 47.45px;
		
	}
}
