/* Parent container */
.industry-content {
    margin-top: 50px;
}

/* Title styling */
.industry-content .industry-title {
    color: #4a00e0;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 30px;
}

/* Card container */
.industry-content .industry-cards {
    display: flex;
    flex-wrap: wrap;
    
    justify-content: center;
}

/* Individual card */
.industry-content .industry-card {
    border: 1px solid #ddd;
    border-radius: 8px;
   
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    height: 100%; /* Ensures consistent height */
}

.industry-content .industry-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
}

/* Card image */
.industry-content .industry-card .indust-img {
    max-height: 270px;
    object-fit: contain;
    margin: 10px auto;
    display: block;
   
}

/* Card title */
.industry-content .industry-card-title {
    font-size: 22px;
    font-weight: 500;
     
}

/* Card text */
.industry-content .industry-card-text {
    font-size: 16px;
    line-height: 1.5;
    margin-top: 10px;
}
