.blog-content .big-letter {
	font-family: "Montserrat", sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 28px;
	line-height: 28px;
}

.blog-content {
	padding: 50px 14vw;
}

.blog-content p {
	font-family: "Manrope", sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 28px;
	padding-bottom: 15px;
	color: #000;
	/*text-align: justify;*/
}

.blog-content h2 {
	font-family: "Manrope", sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 28px;
	line-height: 41px;

	padding-bottom: 7px;
}

.blog-content h3 {
	font-family: "Manrope", sans-serif;
	font-weight: 700;
	font-size: 18px;
	line-height: 32px;
}

.blog-content ol li {
	color: black;
}

.blog-content .img-wrap {
	border: 2px solid white;
	background: #ffffff;
	border-radius: 5px;
	text-align: center;
	margin-bottom: 34px;
}

.img-wrap .main-img {
	max-width: 100%;
}

.blog-content li {
	line-height: 36px;
	color: #000;
}

.blog-content li > span {
	color: black;
	font-weight: 400;
}

.blog-content .blog-author img {
	width: 70.57px;
	height: 70.57px;
}

.blog-content .blog-author p {
	padding: 0;
	margin-bottom: 0;
	color: black;
}

.blue-line {
	position: absolute;
	top: 50px;
	left: -1vw;
}

.blue-div {
	position: absolute;
	width: 200vw;
	height: 50%;
	left: -50vw;
	top: 50%;
	transform: translate(0, -50%);
	z-index: -1;
	background: #f4fafe;
}

@media (max-width: 1000px) {
	.blog-content {
		padding: 50px 5vw;
	}

	.blue-line {
		left: -13vw;
	}
}
.blog-content a{
	text-decoration: none;
}
