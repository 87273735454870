@import url("https://cdn.syncfusion.com/ej2/material.css");

.card1 img{
    width: 70%;
    margin-top: 60px;
}
.card2 input{
    border: none;
    border-bottom: 1px solid rgb(92, 91, 91);
    border-radius: none;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: 15px;
    width: 100%;
}
.card2 input::placeholder{
    font-size: 15px;
    color: rgb(160, 163, 165);
}
.card2 label{
    margin-left: -10px;
}
.required:after {
    content:" *";
    color: red;
  }

.schedule-btn{
    text-align: left;
    background-color: blue;
    color: #fff;
    width: 200px;
    margin-top: 20px;
}

.invalid {
    border-color: red;
    border-bottom: 2px solid red;
    color: red;
}


