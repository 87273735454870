.packaging-content{
    margin-top: 80px;
}
.packaging-content h2{
    margin-top: 25px;
    font-weight: 900;
    font-size: 42px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-style: normal;
	color: #200b03;
    line-height: 50px;
    text-align: center;
    margin-bottom: 45px;
}
.packaging-content .packaging-content3{
    border: 2px solid black;
    padding: 20px;
    padding-top: 0px;
    border-radius: 40px;
    text-align: left;
    padding-bottom: 40px;
    margin-top: 12px;
    
}
.packaging-content  .card-title {
    margin-top: 10px;
    font-weight: 900;
    font-size: 27px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-style: normal;
	color: #200b03;
    margin-bottom: 20px;
    line-height: 30px;
    text-align: center;
}
.packaging-content .packaging-content3 img{
    margin-left: -18px;
    width:110%;
    margin-top: 2px;
    

}
.packaging-content .custom-packagingcard .card-text {
    list-style-type: square;
    
}
.packaging-content .custom-packagingcard .card-text  li{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 28px;
    color: #0f0f0f;
	margin-top: 8px;
    margin-bottom: 30px;
    
}
.packaging-content .highlight-content2{
    height: 100%;
    width: 100%;
    border: 1px solid black;
    border-radius: 25px;
    padding: 35px;
    background-color: #3157ef;
    color: white;
}
.packaging-content .custom-packagingcard {
    background-color: #fff;
    border: 2px solid #131212;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
 
  }
  
  .packaging-content .custom-packagingcard:hover {
    transform: translateY(-5px);
  }
  
  .packaging-content .card-img-top {
    height: 220px;
    object-fit: cover;
    border-bottom: 1px solid #ddd;
    
  }
  
  .packaging-content .card-body {
    padding: 20px;
  }
 
  
  
@media (max-width: 800px) {
    .packaging-content h2{
        font-size: 30px;
        line-height: 40px;
        margin-top: 15px;
    }
    .packaging-content .card-img-top {
        height: 180px;
      }
    .packaging-content .card-title{
        font-size: 25px;
        line-height: 30px;
        margin-top: 15px;
    }
    .packaging-content .custom-packagingcard .card-text li{
        font-size: 20px;
       
    }
  
}