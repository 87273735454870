:root {
  --cards: 5;
  --cardHeight: 105vh;
  --cardTopPadding: 1.5em;
  --cardMargin: 4vw;
}

.features-content{
  position: relative;
  margin: 0 auto;  
}
.features-content  h2{
  margin-top: 60px;
  font-weight: 900;
  font-size: 40px;
  font-family: 'Poppins';
  font-style: normal;
  color: blue;
  line-height: 55px;
}

#cards {
  list-style: none;
  padding-left: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(var(--cards), var(--cardHeight));
  gap: var(--cardMargin);
  padding-bottom: calc(var(--cards) * var(--cardTopPadding));
  margin-bottom: 40px;
}

#card1 {
  --index: 1;
}
#card2 {
  --index: 2;
}
#card3 {
  --index: 3;
}
#card4 {
  --index: 4;
}
#card5 {
  --index: 5;
}
.features-content .card{ 
  position: sticky;
  top: 0;
  background-color: #adc4db;
  padding-top: calc(var(--index) * var(--cardTopPadding)); 
  margin-top: calc(var(--index) * var(--cardTopPadding)); 
  box-shadow: 0 2px 5px 9px #afadad;
}
.features-content .card .card-body{
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(15, 18, 24, 0.1);
  padding: 30px;
  height: var(--cardHeight);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}
.features-content .card .card-body .feature-image-card{
  display: flex;
  align-items: center;
  justify-content: center;
    
}

.features-content .card .card-body .feature-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.features-content .card .card-body .feature-text-container {
  padding: 30px;
  border-radius: 8px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #dfdfeb;
}

.features-content .card .card-body .feature-heading {
  font-size: 30px;
  font-weight: bold;
  color: #4a4aef;
  margin-bottom: 20px;
}

.features-content .card .card-body .feature-description {
  font-size: 18px;
  line-height: 1.5;
  color: #111111;
  margin-bottom: 15px;
}

.features-content .card .card-body .feature-list {
  list-style-type: square;
  font-size: 18px;
  margin-left: -10px;
  
}
.features-content .card .card-body .feature-list li {
  color: #333;
  margin-bottom: 10px; 
}

.features-content .card .card-body .feature-list li strong {
  color: #4a4aef; 
}
/* Mobile View */
@media (max-width: 768px) {
  :root {
    --cardHeight: auto; /* Adjust height for mobile */
    --cardTopPadding: 0; /* Remove extra padding */
    --cardMargin: 2vw; /* Reduce margin for mobile */
  }

  #cards {
    grid-template-rows: auto; /* Adjust rows for mobile */
    gap: var(--cardMargin);
    padding-bottom: 0;
    margin-bottom: var(--cardMargin);
  }

  .features-content .card {
    position: static; /* Disable sticky positioning */
    padding-top: 0; /* Remove padding for cards */
  }

  .features-content .card .card-body {
    height: auto; /* Adjust height to fit content */
    transition: none; /* Disable animations */
  }

  .features-content .card .card-body .feature-text-container {
    box-shadow: none; /* Simplify shadow for mobile */
  }
  .feature-row{
    flex-direction: column; /* Stack rows vertically */
    gap:20px;
}

.feature-row, .image-image-card {
    width: 110%; /* Full width for each section */
   
}

}







