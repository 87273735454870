.product-engagement {
	padding: 80px 0;
}
.product-engagement h1 {
	font-family: Montserrat, sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 28px;
	line-height: 41px;
}

.product-engagement h3 {
	font-family: Montserrat, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 25px;
    margin-top: 10px;
	color: rgb(228, 224, 224);
}

@media (max-width: 800px) {
	.product-engagement {
		padding: 45px 35px;
	}
}
.product-engagement img{
	margin-top: -100px;
	margin-left: -70px;
}