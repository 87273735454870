.position-value .position-content{
    width: 500px;
    padding-top:40px;
    padding-bottom: 40px;
    padding-right: 10px;
}
.position-value .position-content strong{
    color: #FEBF10;
    text-align: left;
    font-size: 19px;
    font-style: normal;
    font-weight: 550;
    line-height: 41px;
    margin-top: 60px;
}
.position-value .position-content h2{
    color: #FFF;
    font-size: 28px;
    margin-top: 15px;
    font-weight: 600;
    line-height: 40px
}
.position-value .position-content p{
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin-top: 20px;
}
.position-value .position-content span{
    color: #FFF;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin-top: 40px;
}
.position-value .position-content hr{
    width:50%;
    height: 3px;
    background-color: #FFF;
}

.position-value .position-imagefix{
	text-align: right;
}
.position-value .position-imagefix img{
	height: 550px;
}

.position-value .position-content .para-content{
	border-radius: 40px;
	padding: 10px;
	text-align: center;
	height: 40px;
    border: none;
	margin-top: 25px;
	font-size: 15px;
	font-weight: 500;
	color: #ffff;
    background-color: #FEBF10;
}
@media (max-width: 800px) {
.position-value .position-imagefix{
    position: relative;
    
    text-align: center;
}
.position-value .position-imagefix img{
	height: 350px;
    width: 100%;
}
}