.blogtophead{
    padding-bottom: 90px;
    padding-top: 30px;
}
.blogtophead h1{
    font-weight: 900;
    font-family: "Montserrat", sans-serif;
    color: black;
}


.blogtophead h4{
font-size: 19px;
font-weight: 520;
font-family: "Montserrat", sans-serif;
color: rgb(59, 59, 63);
margin-top: 15px;
line-height: 25px;
}