.roi-content {
    margin-top: 60px;
}

.roi-content .retail-content2 h2{
    font-weight: 900;
    font-size: 32px;
	font-family: 'Poppins';
	font-style: normal;
	color: #200b03;
    line-height: 50px;
    margin-bottom: 40px;
}
.roi-content .retail-content2 span{
    color: #3157EF;
}

.roi-content .retail-content2 p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 32px;
    color: black;
}
.roi-content .retail-content2 ul{
    list-style-type: square;
}

.roi-content .retail-content2  .list-content{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 28px;
    color: black;
	margin-top: 5px;
    margin-left: -10px;
    
}
@media (max-width: 800px) {
    .roi-content .retail-content2 img{
        text-align: center;
    }
    .roi-content .retail-content2 h2{
        font-size: 30px;
       
    }
}