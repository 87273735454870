.challenges-content h2{
    margin-top: 25px;
    font-weight: 900;
    font-size: 32px;
	font-family: 'Poppins';
	font-style: normal;
	color: #200b03;
    line-height: 50px;
    margin-bottom: 45px;
}
.challenges-content span{
    color: #3157EF;
}
.challenges-content .challenges-content2 {
    background: #fff;
    border-radius: 10px;
    box-shadow: 5px 4px 25px rgba(118, 118, 214, 0.36);
    height: 160px;
    width: 100%;
    max-width: 250px;
    position: relative;
    padding-top: 30px; 
    
}
.challenges-content .challenges-content2 p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: black;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
}
.challenges-content .challenges-content2 img {
    position: absolute;
    top: -14px;
    right: -10px;
    width: 15%;
    height: 22%;
}

.challenges-content .challenges-content3 h3{
    font-weight: 800;
    font-size: 28px;

}
.challenges-content .challenges-content3 span{
    color: #3157EF;

}
@media (max-width: 800px) {
    .challenges-content h3{
        margin-top: 15px;
        font-size: 28px;
        margin-bottom: 0px;
    }
    .challenges-content .challenges-content2 {
        height:100%; 
    }
    .challenges-content .challenges-content2 p{
        font-size: 13px;
    }
    .challenges-content .challenges-content3 h2{
        font-weight: 800;
        font-size: 20px;
    
    }
    .challenges-content .quard {
        width: 50%; 
       
    }
    .challenges-content .challenges-content2 img {
        width: 15%;
        height: 15%;
    }
  
}
