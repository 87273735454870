.blog-content .list-style{
    margin-left: 30px;
    list-style-type: square;
    
  }
  .blog-content .blog-author p{
    padding: 0;
    margin-bottom: 0;
    color: black;
  }
  .blue-line {
    position: absolute;
    top: 50px;
    left: -1vw;
  }
  
  .blue-div {
    position: absolute;
    width: 200vw;
    height: 50%;
    left: -50vw;
    top: 50%;
    transform: translate(0, -50%);
    z-index: -1;
    background: #F4FAFE;
  }
  
  @media(max-width:800px){
      .blog-content{
        padding: 50px 5vw;
      }
     .blue-line {
        left: -13vw;
      }
  }
  
  
  .blog-content .highlight_text{
      font-weight: 600;
  }
  .blog-content .body_image{
     width:100%;
     height:90%;
    
  }
  .blog-content .bordered_content{
      border: 2px solid grey;
      padding: 20px;
  }