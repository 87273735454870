.suggestblog{
    padding-bottom: 50px;
    padding-top: 50px;
}
.suggestblog h1{
    font-weight: 900;
    font-family: "Montserrat", sans-serif;
    color: black;
    font-size: 16px;
    margin-top: 20px;
}
.suggestblog h2{
    font-weight: 900;
    font-family: "Montserrat", sans-serif;
    color: black;
    font-size: 25px;
}
.suggestblog h4{
    font-size: 19px;
    font-weight: 520;
    font-family: "Montserrat", sans-serif;
    color: rgb(59, 59, 63);
    margin-top: 10px;
    line-height: 25px;
}
.suggestblog button{
    margin-top: 30px;
}
.suggested_blog_image{
    width: 100%;
    height: 400px;
    border-radius: 10px;
}

@media(max-width:800px){
    .suggested_blog_image{
        display: none;
    }
}