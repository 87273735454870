  
#root {
    overflow-x: hidden; 
}
.main-image{
  height:250px; 
}
.blog-content{
  padding: 50px 14vw;
}
.blog-content h5{
  text-align: center;
  margin-top: 0;
}
.main-img{
  height: 300px;
 
}
.blog-content h4{
  font-size: 20px;
  margin:0;
  font-weight: 800;
}
.blog-content p{
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  /*text-align: justify;*/
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  padding-bottom: 15px;
  color: black;
}
.blog-content ul li{
  margin-left: -15px;
}
.blog-content .blog-author img {
  width: 70.57px;
  height: 70.57px;
}

.blog-content .blog-author p{
  padding: 0;
  margin-bottom: 0;
  color: black;
}


.blue-line {
  position: absolute;
  top: 50px;
  left: -1vw;
}

.blue-div {
  position: absolute;
  width: 200vw;
  height: 50%;
  left: -50vw;
  top: 50%;
  transform: translate(0, -50%);
  z-index: -1;
  background: #F4FAFE;
}

@media(max-width:1000px){
  .blog-content{
      padding: 50px 5vw;
  }

  .blue-line {
      left: -13vw;
  }
  .main-img{
      height:200px;
      
  }
  .main-image{
    height:250px;
    margin-top: 10px;
}

}

