/* Navbar */
.navbar {
  padding-top: 8px;
  padding-bottom: 8px;
  position: -webkit-sticky;
  position: sticky;
  top: -5px;
  z-index: 2;
  background-color: #fff;
}
.navbar-brand img{
  height: 35px;
  width: 150px;
  margin-top: -10px;
}
.nav-item a {
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  line-height: 22px;
  margin-top: 3px;
}
.nav-link {
  margin-left: 18px;
}
.navButtons .btn-BlueBg {
    width: 115px;
    height: 40px;
}
.navContact{
  margin-top: 18px; 
}

.navButtons span{
  font-size: 15px;
}
.navButtons p{
  margin-right: 10px;
  text-decoration: none;
  font-size: 15px;
  color: #3f3f3f;
  font-weight: 550;
}
.navbar .dropdown-item{
  margin-top: -8px;
  margin-bottom: -8px;
  padding: 8px 1rem;
}

.navbar .dropdown-item:hover {
  color: white !important;
  background: #3157EE !important;
}
@media(min-width: 899px) and (max-width: 1199px){
  .navbar {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .nav-link {
    margin-left: 12px;
  }
  .navContact{
    display: none;
  }
}
/* Navbar */

.btn-WhiteBg {
  background-color: rgb(229, 234, 240);
  color: #3f3f3f;   
  border-radius: 5px;
}
.btn-BlueBg {
    background-color: #3157EF;
    color: white;
    border-radius: 5px;
}
.btn-BlueBg:hover{
  background-color: #3157EF;
  color: white;
  border-radius: 5px;
}
.btn-WhiteBg:hover{
  background-color: rgb(229, 234, 240);;
  color: #3157EF;
  border-radius: 5px;
}
.absBg1 {
  position: absolute;
  width: 792px;
  height: 792px;
  top: 0px;
  right: 0px;
  z-index: -999;
}
.absBg2 {
  position: absolute;
  width: 963px;
  height: 963px;
  top: 0px;
  right: 0px;
  z-index: -999;
}
.myBlue {
  color: #3157EF;
}
.img-circle{
  border-radius: 50%;
  width: 50px !important;
}

.top-head h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 61px;
}
.top-head h2 {
    font-style: normal;
    font-weight: 800;
    font-size: 21px;
    line-height: 29px;
    margin-top: 25px;
    color: #000000;
}
.top-head h3{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 31px;
     margin-top: 20px;
    text-align: left;
    
}
.top-head h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    margin-top: 18px;
}
.top-head h5 {
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  margin-top: -10px;
  color: #3157EF;
} 
.top-head .btn-BlueBg {
    width: 277px;
    height: 55px;
    margin-top: 30px;
}
.headShow-card {
  background: #FFFFFF;
  box-shadow: 0px 4px 81px rgba(27, 50, 139, 0.2);
  border-radius: 10px;
  margin-top: -45px;
  margin-left: 10%;
  z-index: 9;
  padding: 45px;

}

.headShow-card2 {
  width: 230.17px;
  height: 164.77px;
  display: grid;
  place-items: center;
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 4px 81px rgba(27, 50, 139, 0.2);
  border-radius: 10px;
  margin: auto;
  margin-right: -90px;
  margin-top: -16%;
}

.headShow-card3 {
  width: 83.83px;
  height: 80.94px;
  background: #FFFFFF;
  box-shadow: 0px 4px 81px rgba(27, 50, 139, 0.2);
  border-radius: 10px;
  display: grid;
  place-items: center;
  position: relative;
}

  .show-area {
    margin: auto;
    margin-top: -50px;
    width: 65%;

  }

  .headShow-card img {
    width: 100%;
  }
  .plat-card{
    background: #FFFFFF;
    box-shadow: 0px 7px 20px rgba(27, 50, 139, 0.1);
    border-radius: 11px;
    padding: 20px  25px 20px 25px;
  }
  
.footer {
  padding: 60px;
  background: linear-gradient(180deg, #80c9f331 0%, rgba(128, 200, 243, 0) 100%);
}
.footer img{
  height: 125px;
  width: 140px;
}
.footer h2{
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 22px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
  margin-top: 35px;
}
.footer p{
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #3f3f3f;
  margin-top: 30px;
}
/* Footer Home page */
.footerhome{
  padding-top: 60px;
  background: linear-gradient(180deg, #80c9f331 0%, rgba(128, 200, 243, 0) 100%);
}
.footerhome h1{
  font-family: 'Poppins';
  font-weight: 800;
  font-size: 16px;
}
.footerhome h2{
  font-size: 38px;
  font-family: 'Poppins';
  font-weight: 900;
  line-height: 47px;
  margin-top: -8px;
}
.footerhome button{
  width: 160px;
  margin-top: 20px;
  margin-left: 2px;
} 
@media (max-width: 800px) {
  .footerhome{
    text-align: left;
  }
  .footerhome h1{
    font-weight: 600;
    font-size: 13px;
  }
  .footerhome h2{
    font-size: 25px;
    font-family: 'Poppins';
    font-weight: 900;
    line-height: 32px;
    margin-top: -8px;
    text-align: left;
  }
}
/* End Footer Home page */

.logoCont{
padding: 45px 60px 45px 60px;
text-align: center;
}

.shadow-effect {
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    border:1px solid #ECECEC;
    box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02);
}

.showContent h5{
  height: auto;
}
.showContent button.readmore-btn{
  background-color: black;
}
.text{
  display: inline;
  width: 100%;
}
.footerTwo{
  font-weight: bold;
font-size: 36px !important;
line-height: 25px !important;
/* identical to box height, or 69% */
text-align: center;
letter-spacing: -0.02em !important;
color: #000000;

}
.footer{
  text-align: center;
}
.footer button{
  display: inline-block;
  margin-top: 25px;
}

.hide-sm {
  display: revert;
}
.show-sm {
  display: none;
}
.dark-bg{
  background-color: #060918;
  color: white;
}
.px-6 {
  padding: 0 20%;
}

.extra-margin{
  margin-bottom: -16%;
}

.magic-margin {
  margin-bottom: 10%
}
.not-vis{
  background-color: #000000;
}
.btn-index{
  position: absolute;
}
.four0fourPage{
  text-align: center;
}
.hide{
  display:none;
}