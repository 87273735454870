.faq-section .question {
	display: inline-block;
	font-weight: 550;
	font-size: 18px;
	line-height: 20px;
}
.faq-section .ans {
	margin-left: 65px;
}

.faq-section .btn {
	margin-right: 0;
    margin-right: 5px;
}

.faq-section {
	font-family: "Manrope", sans-serif;
	font-style: normal;
}
.contactus h2{
	font-weight: 900;
	font-family: "Manrope", sans-serif;
	font-style: normal;
}
.faq-section .ans{
	font-weight: 500;
	font-size: 16px;
	line-height: 25px;
    max-width: 80%;
    color: rgba(15, 13, 13, 0.6);
    margin-bottom: 20px;
}
