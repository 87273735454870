.packing-tophead{
	position: relative;
}
.packing-tophead .packing-content h1{
    margin-top: 25px;
    font-weight: 900;
    font-size: 45px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-style: normal;
	color: #060918;
    line-height: 55px;
}
.packing-tophead .packing-content p{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #3F3F3F;
	margin-top: 20px;
}
.packing-tophead .packing-content span{
    color: blue;
    font-weight: 550;
}
.packing-tophead .packing-content input{
    padding-top:15px;
    padding-bottom: 15px;
    border-color: #b3b0b0;
    border-radius: 15px;
}
.packing-tophead .packing-content input::placeholder{
    font-size: 16px;
    color: #b3b0b0;
}
.packing-tophead .packing-content button{
    border: none;
    background-color: blue;
    border-radius: 35px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 50px;
    padding-right: 50px;
    color: #ffff;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 500;
}
.packing-tophead .highlight-content{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
   
}
.packing-tophead .highlight-content .tab-container {
    display: flex;
    align-items: center;
    background: white;
   
    border-radius: 30px;
    box-shadow: 1px 7px 8px 7px rgba(0, 0, 0, 0.1);
}

.packing-tophead .highlight-content .tab {
    text-decoration: none;
    color: black;
    font-weight: 500;
    padding: 10px 20px;
    text-align: center;
}

.packing-tophead .highlight-content .separator {
    width: 1px;
    height: 30px;
    background-color: #a09a9a;
    margin: 0 10px;
}


.packing-tophead .highlight-content button{
    border: none;
    background-color: white;
    font-size: 18px;
    text-align: center;
    padding: 10px;
    border-radius: 50px;
    transition: background-color 0.3s ease;
}
.packing-tophead .highlight-content button:hover {
    background-color: lightgrey; 
}
.packing-tophead .highlight-content button.clicked:hover {
    background-color: skyblue; 
}

@media (max-width: 800px) {
    .packing-tophead{
        position: relative;
        margin-top: 100px;	
    }
    .packing-tophead .packing-content h2{
        font-weight: 800;
        font-size: 30px;
        line-height: 40px;
        margin-top: 0px;
    }
    .packing-tophead .packing-content p{
        font-size: 18px;
        line-height: 28px;
    }
    .packing-tophead .packing-content button{
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 25px;
        padding-right: 25px;
        font-size: 17px;  
        margin-left: -5px; 
    }
    .packing-tophead .packing-content img{
        margin-top: 25px;
    }
    .packing-tophead .highlight-content{
        display: none;
        margin-left: 80px;
        margin-right: 80px;
        text-align: center;
        box-shadow: 0 0 10px #3F3F3F;
        padding-top: 25px;
        padding-bottom: 25px;
        border-radius: 60px;
        margin-top: 40px;
        padding-left: 50px;
        padding-right: 50px;
    }
}