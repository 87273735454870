.sitehead hr{
    color: rgb(10, 9, 9);
    height: 3px;
}
.sitehead h1{
    text-align: left;
    font-family: "Montserrat", sans-serif;
    margin-left: 5px;
}
.sitehead h3{
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
}
.sitehead ul li{
    overflow: hidden;
    margin-top: 5px;
    font-size: 18px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    margin-left: -25px;
    text-decoration: none;
    color: blue;
}
