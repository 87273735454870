@media(max-width:800px) {
  .dropdown-menu-useCase {
    position: absolute;
    left: 50%;
    opacity: 10000;
    z-index: 100000;

  }

  .footerTwo {
    font-weight: bold;
    font-size: 28px !important;
    line-height: 34px !important;
    /* identical to box height, or 69% */
    text-align: justify;
    letter-spacing: -0.02em !important;
    color: #000000;

  }

  .nav-link {
    margin-left: 0px;
  }

  .navbar-toggler {
    background-color: #3157EF;
    color: #FFFFFF;
    z-index: 999;
  }

  .navbar-collapse {
    z-index: 999 !important;
  }

  .navbar-toggler .navbar-toggler-icon {
    color: #FFFFFF !important;
    fill: #FFFFFF;
    background-image: none;
    display: grid;
    place-items: center;
  }

  .show .navButtons .btn-BlueBg {
    margin-top: 20px;
  }

  .top-head h5 {
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 27px;
    color: #3157EF;
  }

  .top-head h1 {
    font-style: normal;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 38px;
    color: #000000;
  }

  .top-head h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */


    color: rgba(0, 0, 0, 0.6);
  }

  .top-head h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 31px;
    /* identical to box height, or 155% */
    margin-top: 12px;
    text-align: left;

  }

  .top-head h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */
    color: rgba(0, 0, 0, 0.6);

  }

  .btn-explore {
    margin-top: -3px;
    margin-left: -20px;
  }

  .svgCont {
    display: none;
  }

  .absBg1 {
    display: none;
  }

  .headShow-card2 {
    margin-top: -3%;
    margin-right: -15px;
  }

  .absBg2 {
    display: none;
  }

  .top-head {
    padding-top: 36px;
    padding-bottom: 36px;
  }

  .show-area {
    width: 100%;
    margin-top: 15%;
    display: grid;
    place-items: center;

  }

  .allLogos .col {
    min-width: 50% !important;
    padding-bottom: 35px;

  }

  .footer .allLogos .col img {
    width: 100%;
  }

  .container {
    padding-left: 35px;
    padding-right: 35px;
  }

  .allLogos {
    text-align: center;
    padding-bottom: 20px;
  }


  .plat-card {
    /* width: 315px;
    height: 229px; */
    background: #FFFFFF;
    box-shadow: 0px 7px 20px rgba(27, 50, 139, 0.1);
    border-radius: 11px;
    padding: 20px 25px 20px 25px;
    margin-top: 30px;
  }

  .app-less {
    margin-top: 20px;
    min-width: 100%;
  }

  .app-less img {
    width: 100%;
  }

  .card-2 {
    margin-top: 0%;
  }

  .mobView {
    display: block !important;
    width: 100%;

  }

  .platPotrait {
    display: none;
  }

  .acc2 {
    margin-left: 0%;
  }

  .acc3 {
    margin-left: 0%;
  }

  .accordion {
    margin-top: 10%;
  }

  /* ****************************************************Dont forget to remove this******************************************************* */
  /* .video-overlay{
  display: none;
} */
  /* ****************************************************Dont forget to remove this******************************************************* */

  .myPlatformtwo {
    margin-top: 2%;
  }

  .myPlatformtwo h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 26px;
    /* or 124% */

    text-align: center;
    letter-spacing: -0.02em;

    color: #000000;
  }

  .myPlatformtwo h5 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    /* or 156% */

    text-align: center;

    color: rgba(0, 0, 0, 0.6);
  }

  .digiExp h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 38px;
    /* or 119% */


    color: #000000;
  }

  .svgText2 h5 {
    margin-left: 12%;
    margin-top: 0%;
  }

  .mobSvg {
    margin-top: 10%;
  }

  .left-card {
    margin-right: 0px;
    margin-left: 0px;
  }

  .right-card {
    margin-right: 0px;
    margin-left: 0px;
  }

  .no-gutters .col {
    padding-right: 10px;
    padding-left: 10px;
  }

  .cloudImg {
    min-width: 100% !important;
    margin-bottom: 35px;
  }

  .cloudImg img {
    width: 100%;
    margin-top: 0%;
  }

  .point-card {
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(27, 50, 139, 0.2);
    border-radius: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    /* or 139% */
    text-align: left;
    padding: 15px;
    color: #000000;
    margin-bottom: 35px;
  }

  .point-card img {
    float: left;
    width: 30px;
    margin: -5px 10px 10px 10px;
  }

  .acc-cont2 {
    border-radius: 11px;
    padding: 0px;
    width: 100%;
    margin: auto;
    margin-left: 0px;
    margin-top: 5%;
    z-index: 555;
  }

  .acc-cont {
    background: #FFFFFF;
    box-shadow: 0px 4px 31px rgba(27, 50, 139, 0.2);
    border-radius: 11px;
    padding: 25px;
    width: 100%;
    margin: auto;
    margin-right: 0px;
    margin-top: 5%;
    position: relative;
    z-index: 555;
  }

  .EnterpriseAccordianTwo {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .hide-sm {
    display: none !important;
  }

  .show-sm {
    display: revert;
  }

  .px-6 {
    padding: 0 5px;
  }

  .extra-margin {
    margin-bottom: -400px;
  }

  .magic-margin {
    margin-bottom: 400px;
  }

  .navbar {
    padding-top: 30px;
  }

  .navbar-collapse {
    z-index: 1999 !important;
    background-color: white;
    text-align: left;
    padding: 20px;
    padding-left: 35px;
    margin-left: -10px;
    width: 130%;
    margin-top: 10px;
    border: 1px solid #ddd; 
    border-radius: 8px; 
    box-shadow: 0px 4px 6px rgb(154, 154, 207); 
    
  }



  .headShow-card3 {
    margin-top: 10px;
  }

  .headShow-card {
    margin-top: -7px;
    margin-left: -6px;
  }
}

@media(max-width: 3150px) {
  .mobView {
    display: none;
  }
}

@media(min-width: 1100px) {
  .footer .allLogos {
    text-align: center;
    padding-right: 20%;
    padding-left: 20%;
  }
}