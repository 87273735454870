#root {
    overflow-x: hidden; 
}
.blog-content{
  padding: 25px 12vw;
}
.blog-content h5{
  text-align: center;
  margin-top: 0;
}
.blog-content h2{
   font-size: 20px;
   margin:0;
   font-weight: 800;
}
.blog-content p{
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  /*text-align: justify;*/
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  padding-bottom: 15px;
  color: black;
}
.blog-content ul li{
  margin-left: -15px;
}
.blog-content .main-image{
    width: 100%;
}
.blog-content .blog-author p{
  padding: 0;
  margin-bottom: 0;
  color: black;
}
.blue-line {
  position: absolute;
  top: 50px;
  left: -1vw;
}

.blue-div {
  position: absolute;
  width: 200vw;
  height: 50%;
  left: -50vw;
  top: 50%;
  transform: translate(0, -50%);
  z-index: -1;
  background: #F4FAFE;
}

@media(max-width:800px){
    .blog-content{
      padding: 50px 5vw;
    }
   .blue-line {
      left: -13vw;
    }
}

