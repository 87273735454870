.footer-new-infra {
	width: 100%;
    margin-top: 2%;
	color: #000000;
    background-color: #ffffff;
}
.footer-new-infra .logo{
	margin-top: -3px;
	margin-left: -2px;
    height: 38px;
    width: 200px;
}
.footer-new-infra h4{
    font-weight: 550;
    font-size: 13px;
    color: #000000;
    opacity: 0.8;
	line-height: 20px;
	margin-left: -2px;
    margin-top: 15px;
}
.footer-new-infra .footer-table tr td{
    width: 200px !important;
    text-align: left !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: #000000;
    opacity: 0.8;
    text-decoration: none;
}
.footer-new-infra .footer-table tbody tr td a{
    text-decoration: none;
    margin-top: 0px;
    color: #000000;
}

.footer-new-infra h5{
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #000;
}
.footer-new-infra .table-no-border {
    border: none;
}
.footer-new-infra .table-no-border th,
.footer-new-infra .table-no-border td {
    border: none;
}
.footer-new-infra .footer-table .qrlogo{
	margin-left: -10px;
    height: 125px;
    width: 130px;
    margin-top: -75px;
}

.footer-new-infra .copyright{
	padding-bottom: 0px;
	margin-bottom: 0px;
    text-align: center;
}
.footer-new-infra .copyright small{
	padding-bottom: 0px;
	margin-bottom: 0px;
	font-size: 16px;
    margin-top: 8px;
}

@media(max-width:992px){
	.copyright{
		margin-left: -18px;
	}
    #footer-add-logo{
       display: none;
    }
    .footer-table{
        margin-left: 0px;
    }
    .footer-table .qrlogo{
        margin-left: -10px;
        height: 100px;
        width: 110px;
    }
}