.contactus.footer {
	width: 100%;
	background: #060918;
	padding: 50px 5px;
	color: white;
}

.contactus.footer h2 {
	color: white;
	font-weight: 800;
	font-size: 32px;
	line-height: 39px;
	margin-top: -20px;
}

.contactus.footer p {
	font-weight: 500;
	font-size: 16px;
	line-height: 25px;
	color: white;
}




.contactus.footer .input-wrapper button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
	margin-top: -1px;
    white-space: nowrap; 
}


@media (max-width: 800px) {
	.contactus.footer h2 {
		color: white;
		font-weight: 600;
		font-size: 28px;
		line-height: 39px;
	}
	.contactus.footer .input-wrapper {
		margin-left: -10px;
	}
	.contactus.footer .map{
		height: 30px;
	}
	.contactus.footer .input-wrapper {
        flex-direction: column; /* Stack elements vertically */
        align-items: stretch; /* Ensure elements take full width */
    }

    .contactus.footer .input-wrapper input {
        margin-bottom: 10px; /* Adds spacing between input and button */
        width: 100%; /* Ensure full width for the input field */
    }

    .contactus.footer .input-wrapper button {
        width: 50%; /* Ensure full width for the button */
		align-self: center;
    }
}

