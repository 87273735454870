.qreachknowmore-faqs{
    margin-top: 30px;
}
.qreachknowmore-faqs  h2{
    margin-top: 25px;
    font-weight: 900;
    font-size: 40px;
	font-family: 'Poppins';
	font-style: normal;
	color: rgb(3, 3, 3);
    line-height: 55px;
}
.qreachknowmore-faqs .faqs-content{
    background-color: rgb(2, 2, 90);
    padding: 20px;
}
.qreachknowmore-faqs .faqs-content h3{
    margin-top: 25px;
    font-weight: 900;
    font-size: 45px;
	font-family: 'Poppins';
	font-style: normal;
	color: rgb(248, 244, 244);
    line-height: 55px;
    padding: 20px 10px 20px 60px ;
    
}
.qreachknowmore-faqs .faqs-content img{
    padding-left: 40px;
}
/* FAQ Items */
.qreachknowmore-faqs .faqs-content .faq-item {
    padding: 30px;
    border-bottom: 1px solid #4d7ee7;
    position: relative;
    cursor: pointer;
}

/* Question Styling */
.qreachknowmore-faqs .faqs-content .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    margin-left: -22px;
}

/* Dropdown Icon Wrapper */
.qreachknowmore-faqs .faqs-content .dropdown-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff; 
    transition: transform 0.3s ease;
}

/* Dropdown Icon */
.qreachknowmore-faqs .faqs-content .dropdown-icon {
    font-size: 0.8rem;
    color: #12122c;
    transition: transform 0.3s ease;
}

/* Rotate Icon on Hover */
.qreachknowmore-faqs .faqs-content .faq-item:hover .dropdown-icon-wrapper {
    transform: rotate(180deg);
}

.qreachknowmore-faqs .faqs-content .faq-item:hover .dropdown-icon {
    transform: rotate(180deg);
}

/* Answer Styling (Initially Hidden) */
.qreachknowmore-faqs .faqs-content .faq-answer {
    font-size: 0.9rem;
    color: #ddd;
    margin-top: 10px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    opacity: 0;
}

/* Show Answer on Hover */
.qreachknowmore-faqs .faqs-content .faq-item:hover .faq-answer {
    max-height: 100px;
    opacity: 1;
}
@media (max-width: 768px) {
    .qreachknowmore-faqs .faqs-content img{
       
        width: 100%;
    }
    .qreachknowmore-faqs .faqs-content h3{
        padding-left: 0;
        
    }
    .qreachknowmore-faqs .faqs-content .faq-item {
        padding: 20px;
        
    }
    .qreachknowmore-faqs .faqs-content .dropdown-icon-wrapper {
        
        margin-left: 10px;
    }
}